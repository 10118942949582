var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDashboardSettings, useDidMountEffect } from "hooks";
import { ActionCreators } from "store";
import { getUniqueListBy } from "utils";
/**
 * Useful hook for handling a large portion of the redux / state logic
 * surrounding the location / pod selection dropdowns
 */
export var usePodSelectLogic = function () {
    var _a;
    var dispatch = useDispatch();
    var Dashboard = useDashboardSettings();
    var _b = __read(useState(false), 2), sampleLoaded = _b[0], setSampleLoaded = _b[1];
    var availableCountriesFromStore = useSelector(function (state) { var _a, _b; return (_b = (_a = state.dashboard.filters.available) === null || _a === void 0 ? void 0 : _a.country) === null || _b === void 0 ? void 0 : _b.data; });
    var availablePodsFromStore = useSelector(function (state) { var _a, _b; return (_b = (_a = state.dashboard.filters.available) === null || _a === void 0 ? void 0 : _a.pods) === null || _b === void 0 ? void 0 : _b.data; });
    var availableStatesFromStore = useSelector(function (state) { var _a, _b; return (_b = (_a = state.dashboard.filters.available) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.data; });
    var availableCitiesFromStore = useSelector(function (state) { var _a, _b; return (_b = (_a = state.dashboard.filters.available) === null || _a === void 0 ? void 0 : _a.city) === null || _b === void 0 ? void 0 : _b.data; });
    var selectedPods = useSelector(function (state) { return state.dashboard.filters.selected; });
    /**
     * List of countries available from api, mapped to work with dropdown
     */
    var availableCountries = (availableCountriesFromStore !== null && availableCountriesFromStore !== void 0 ? availableCountriesFromStore : [])
        .map(function (c) { return ({
        label: c.long_name,
        value: c.uuid,
    }); })
        .sort(function (a, b) { return (a.label < b.label ? -1 : 1); });
    /**
     * List of states/regions available from api, mapped to work with dropdown
     */
    var availableStates = (availableStatesFromStore !== null && availableStatesFromStore !== void 0 ? availableStatesFromStore : [])
        .map(function (country) { return ({
        label: country.long_name,
        options: country.states
            .map(function (region) { return ({
            label: region.long_name,
            value: region.uuid,
        }); })
            .sort(function (a, b) { return (a.label < b.label ? -1 : 1); }),
    }); })
        .sort(function (a, b) { return (a.label < b.label ? -1 : 1); });
    /**
     * List of cities available from api, mapped to work with dropdown
     */
    var availableCities = (availableCitiesFromStore !== null && availableCitiesFromStore !== void 0 ? availableCitiesFromStore : [])
        .map(function (region) { return ({
        label: region.long_name,
        options: region.cities
            .map(function (city) { return ({
            label: city.long_name,
            value: city.uuid,
        }); })
            .sort(function (a, b) { return (a.label < b.label ? -1 : 1); }),
    }); })
        .sort(function (a, b) { return (a.label < b.label ? -1 : 1); });
    /**
     * List of pods available from api, mapped to work with dropdown
     */
    var availablePodsByCitySelection = (availablePodsFromStore !== null && availablePodsFromStore !== void 0 ? availablePodsFromStore : []).map(function (city) { return ({
        label: city.long_name,
        options: city.stations
            .map(function (station) { return ({
            label: station.name,
            value: station.uuid,
            pod: station,
        }); })
            .sort(function (a, b) { return (a.label < b.label ? -1 : 1); }),
    }); });
    /**
     * There are cases where a user will unselect a city or country, but there are pods
     * within that city that are already selected.  In that case, those selected options
     * would be hidden in the selected pods dropdown, because their associated places are not selected.
     * So we loop through the user's current selection and re-inject those selections and their
     * city groups
     */
    selectedPods.forEach(function (pod) {
        /**
         * If the selected pod belongs to a city that is not already in the selected cities
         * group list, we add a group for that city, then add the pod to that city's options
         */
        if (!availablePodsByCitySelection
            .map(function (group) { return group.label; })
            .includes(pod.location.city.long_name)) {
            availablePodsByCitySelection.push({
                label: pod.location.city.long_name,
                options: [
                    {
                        label: pod.name,
                        value: pod.uuid,
                        pod: pod,
                    },
                ],
            });
            return;
        }
        /**
         * If the selected pods belongs to a city that is already in the list,
         * but its not in the city's options, we add it to the options
         */
        if (availablePodsByCitySelection
            .map(function (group) { return group.label; })
            .includes(pod.location.city.long_name) &&
            !availablePodsByCitySelection
                .find(function (group) { return group.label === pod.location.city.long_name; })
                .options.map(function (pod) { return pod.value; })
                .includes(pod.uuid)) {
            availablePodsByCitySelection
                .find(function (group) { return group.label === pod.location.city.long_name; })
                .options.push({
                label: pod.name,
                value: pod.uuid,
                pod: pod,
            });
        }
    });
    var availablePods = availablePodsByCitySelection.sort(function (a, b) {
        return a.label < b.label ? -1 : 1;
    });
    /**
     * Series of state variables and setters to keep track of user's current selection
     * in the dropdowns
     */
    var _c = __read(useState({ call: true, selected: [] }), 2), selectedCountries = _c[0], setSelectedCountries = _c[1];
    var _d = __read(useState({ call: true, selected: [] }), 2), selectedStates = _d[0], setSelectedStates = _d[1];
    var _e = __read(useState({ call: true, selected: [] }), 2), selectedCities = _e[0], setSelectedCities = _e[1];
    /**
     * On mount, call to get country list
     */
    useEffect(function () {
        dispatch(ActionCreators.requestLocationList({ level: "country" }));
    }, []);
    /**
     * When selected countries changes, call to get relevant states / regions
     */
    useDidMountEffect(function () {
        if (selectedCountries.selected.length && selectedCountries.call) {
            dispatch(ActionCreators.requestLocationList({
                level: "state",
                uuids: selectedCountries.selected.map(function (c) { return c.value; }),
            }));
        }
        else {
            dispatch(ActionCreators.setLocationList({ level: "state", list: [] }));
            dispatch(ActionCreators.setLocationList({ level: "city", list: [] }));
        }
    }, [selectedCountries]);
    /**
     * When selected states/regions changes, call to get relevant cities
     */
    useDidMountEffect(function () {
        if (selectedStates.selected.length && selectedCountries.call) {
            dispatch(ActionCreators.requestLocationList({
                level: "city",
                uuids: selectedStates.selected.map(function (c) { return c.value; }),
            }));
        }
        else {
            dispatch(ActionCreators.setLocationList({ level: "city", list: [] }));
        }
    }, [selectedStates]);
    /**
     * When selected locations change, call to get all pods in those locations
     */
    useDidMountEffect(function () {
        if (selectedCities.selected.length && selectedCities.call) {
            dispatch(ActionCreators.requestPodList({
                country_uuids: selectedCountries.selected.length
                    ? selectedCountries.selected.map(function (c) { return c.value; })
                    : undefined,
                state_uuids: selectedStates.selected.length
                    ? selectedStates.selected.map(function (c) { return c.value; })
                    : undefined,
                city_uuids: selectedCities.selected.length
                    ? selectedCities.selected.map(function (c) { return c.value; })
                    : undefined,
                page: 1,
                limit: 10000,
            }));
        }
    }, [selectedCities]);
    /**
     * Function to extract the list of countries, states, cities, and districts that are
     * associated with a pod sample, then make api calls to get the location lists
     * for all those countries, states, cities, and districts.  Will also call state
     * setters to set those locations as selected within their respective dropdowns.
     */
    var getLocationsFromSample = function (
    /**
     * The sample from which to extract the locations
     */
    sample, 
    /**
     * Whether or not to make new api calls to get location lists when the
     * dropdown items are programatically selected
     */
    call) {
        /*
         * Derive all country, state, city, and district uuids based on pod sample from settings
         */
        var derivedSelectedCountries = getUniqueListBy(sample.map(function (pod) { return pod.location.country; }), "uuid");
        var derivedSelectedStates = getUniqueListBy(sample.map(function (pod) { return pod.location.state; }), "uuid");
        var derivedSelectedCities = getUniqueListBy(sample.map(function (pod) { return pod.location.city; }), "uuid");
        /**
         * Call to get all locations derived from pod sample from settings,
         * and set those locations as selected in the dropdowns
         */
        if (derivedSelectedCountries.length) {
            dispatch(ActionCreators.requestLocationList({
                level: "state",
                uuids: derivedSelectedCountries.map(function (c) { return c.uuid; }),
            }));
            setSelectedCountries({
                call: call,
                selected: derivedSelectedCountries.map(function (c) { return ({
                    label: c.long_name,
                    value: c.uuid,
                }); }),
            });
        }
        if (derivedSelectedStates.length) {
            dispatch(ActionCreators.requestLocationList({
                level: "city",
                uuids: derivedSelectedStates.map(function (s) { return s.uuid; }),
            }));
            setSelectedStates({
                call: call,
                selected: derivedSelectedStates.map(function (s) { return ({
                    label: s.long_name,
                    value: s.uuid,
                }); }),
            });
        }
        if (derivedSelectedCities.length) {
            setSelectedCities({
                call: call,
                selected: derivedSelectedCities.map(function (c) { return ({
                    label: c.long_name,
                    value: c.uuid,
                }); }),
            });
        }
        if (sample.length) {
            dispatch(ActionCreators.selectPods(sample));
        }
        if (derivedSelectedCountries.length ||
            derivedSelectedStates.length ||
            derivedSelectedCities.length) {
            dispatch(ActionCreators.requestPodList({
                country_uuids: derivedSelectedCountries.map(function (c) { return c.uuid; }),
                state_uuids: derivedSelectedStates.map(function (s) { return s.uuid; }),
                city_uuids: derivedSelectedCities.map(function (c) { return c.uuid; }),
                page: 1,
                limit: 10000,
            }));
        }
    };
    /**
     * When the component mounts and user settings are retrieved, we check and see what pods they have
     * previously set to be in their sample selection.  From those pods, we then extract all
     * pertinent location data, call to get all relevant location lists, and set any country/state/city/district
     * that is found in their sample to be selected in the dropdowns
     *
     * The `sampleLoaded` and `setSampleLoaded` safegaurds assure that this truly runs only once,
     * just after the user settings come in
     */
    useEffect(function () {
        var _a;
        if (((_a = Dashboard === null || Dashboard === void 0 ? void 0 : Dashboard.dashboard) === null || _a === void 0 ? void 0 : _a.sample) && !sampleLoaded) {
            var sample = Dashboard.dashboard.sample.filter(function (p) { return p; }); // extra filter check for safety
            if (!sample.length) {
                setSampleLoaded(true);
                return;
            }
            getLocationsFromSample(sample, false);
            setSampleLoaded(true);
        }
    }, [(_a = Dashboard === null || Dashboard === void 0 ? void 0 : Dashboard.dashboard) === null || _a === void 0 ? void 0 : _a.sample]);
    return {
        getLocationsFromSample: getLocationsFromSample,
        availableCountries: availableCountries,
        availableStates: availableStates,
        availableCities: availableCities,
        availablePods: availablePods,
        selectedCountries: selectedCountries,
        setSelectedCountries: setSelectedCountries,
        selectedStates: selectedStates,
        setSelectedStates: setSelectedStates,
        selectedCities: selectedCities,
        setSelectedCities: setSelectedCities,
        selectedPods: selectedPods,
        sampleLoaded: sampleLoaded,
    };
};
