var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { TimeRanges } from "models";
import { formatUTC } from "utils";
import { ActionCreators } from "store";
import { SelectPods } from "./SelectPods";
import { SelectSpecies } from "./SelectSpecies";
import SelectDate from "./SelectDate";
import { SelectTimeframe } from "./SelectTimeframe";
import { BuildGraphSteps } from "../historics_analytics/widgets";
import { timerangeOptions, timeSelectorOptions, } from "../../../constants";
import { formatDateForAPI } from "../historics_analytics/widgets/graphs/BuildRoseModal";
import { SelectAvg } from "./SelectAvg";
export var DownloadData = function () {
    var _a;
    var _b = __read(useState(BuildGraphSteps.SelectPods), 2), step = _b[0], setStep = _b[1];
    var _c = __read(useState([]), 2), selectedPods = _c[0], setSelectedPods = _c[1];
    var _d = __read(useState([]), 2), selectedSpecies = _d[0], setSelectedSpecies = _d[1];
    var _e = __read(useState(undefined), 2), selectedTimeframe = _e[0], setSelectedTimeframe = _e[1];
    var _f = __read(useState(undefined), 2), from = _f[0], setFrom = _f[1];
    var _g = __read(useState(undefined), 2), to = _g[0], setTo = _g[1];
    var _h = __read(useState(60), 2), periodResolution = _h[0], setPeriodResolution = _h[1];
    var userInfo = useSelector(function (state) { return state.user.info.data; });
    var dispatch = useDispatch();
    var _j = __read(useState(undefined), 2), selectedDate = _j[0], setSelectedDate = _j[1];
    var _k = __read(useState("frame"), 2), dateFormat = _k[0], setDateFormat = _k[1];
    var _l = __read(useState(""), 2), customFrameValue = _l[0], setCustomFrameValue = _l[1];
    var fromBeginningOption = {
        label: "From Beginning",
        value: TimeRanges.FROM_BEGINNING,
        getTime: function () {
            var start = new Date(0);
            return formatUTC(start);
        },
        agoLabel: "",
        shortname: "FB",
    };
    var timerangeOptionsWithBeginning = __spreadArray(__spreadArray([], __read(timerangeOptions), false), [
        fromBeginningOption,
    ], false);
    var handleRequestExport = function (endFrame, startFrame) {
        dispatch(ActionCreators.exportData({
            email: userInfo.email,
            end: endFrame,
            minutes: periodResolution,
            parameters: selectedSpecies.map(function (item) { return item.species_code; }),
            start: startFrame,
            station_uuids: selectedPods.map(function (item) { return item.uuid; }),
        }));
    };
    var onSubmit = function () {
        var _a;
        if (dateFormat === "frame") {
            var start = (_a = timerangeOptionsWithBeginning
                .find(function (item) { return item.value === selectedTimeframe; })) === null || _a === void 0 ? void 0 : _a.getTime(undefined);
            var end = formatUTC(new Date());
            handleRequestExport(end, start);
        }
        else if (dateFormat === "range") {
            var start = formatDateForAPI(selectedDate.from);
            var end = formatDateForAPI(selectedDate.to);
            handleRequestExport(end, start);
        }
        setStep(BuildGraphSteps.SelectPods);
    };
    var steps = (_a = {},
        _a[BuildGraphSteps.SelectPods] = (_jsx(SelectPods, { selectedPods: selectedPods, setSelectedPods: setSelectedPods, setStep: setStep })),
        _a[BuildGraphSteps.SelectParams] = (_jsx(SelectSpecies, { selectedPods: selectedPods, selectedSpecies: selectedSpecies, setSelectedSpecies: setSelectedSpecies, setStep: setStep })),
        _a[BuildGraphSteps.SelectDate] = (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ style: {
                        alignItems: "center",
                        display: "flex",
                        gap: "8px",
                        maxWidth: "320px",
                        padding: "10px",
                    } }, { children: [_jsx("span", { children: "Date format:" }), _jsx(Select, { onChange: function (option) {
                                return setDateFormat(option.value);
                            }, value: [
                                timeSelectorOptions.find(function (item) { return item.value === dateFormat; }),
                            ], options: timeSelectorOptions })] })), dateFormat === "range" && (_jsx(SelectDate, { from: from, to: to, setFrom: setFrom, setTo: setTo, selectedSpecies: selectedSpecies, setStep: setStep, setSelectedDate: setSelectedDate })), dateFormat === "frame" && (_jsx(SelectTimeframe, { timerangeOptionsWithBeginning: timerangeOptionsWithBeginning, selectedTimeframe: selectedTimeframe, setStep: setStep, setSelectedTimeframe: setSelectedTimeframe, customFrameValue: customFrameValue, setCustomFrameValue: setCustomFrameValue }))] })),
        _a[BuildGraphSteps.SelectAvg] = (_jsx(SelectAvg, { setStep: setStep, onSubmit: onSubmit, selectedAvg: periodResolution, setSelectedAvg: setPeriodResolution })),
        _a);
    return (_jsx("div", __assign({ style: {
            display: "flex",
            justifyContent: "center",
        } }, { children: _jsx("div", __assign({ style: { width: "850px" } }, { children: steps[step] })) })));
};
