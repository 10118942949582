var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Tab, Tabs, Typography, useTheme, } from "@mui/material";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import { useEffect, useMemo, useState } from "react";
import LineChart from ".";
import { useSelector } from "react-redux";
import axios from "axios";
import { AQI_SCALE_RANGES, backGround, convertDateToTimezone, plotBand, } from "utils";
import { format } from "date-fns";
import { API_BASE, speciesLabels, unitLabels } from "../../../../../constants";
import { PollutionRose } from "components/pages/historics_analytics/widgets/graphs/RoseBlock/pollutionRoseChart";
var BLACKLISTED_PARAMS = [
    "dewPoint",
    "heatIndex",
    "windChill",
    "windu",
    "windv",
    "windw",
];
var generateTimeFrame = function (timeFrame, endDate) {
    var now = convertDateToTimezone(endDate || new Date(), "local");
    var frameInMillisecond = Math.floor(timeFrame * 24 * 60 * 60 * 1000);
    var start = new Date(now.getTime() - frameInMillisecond);
    return {
        end: format(now, "yyyy-MM-dd' 'HH:mm:ss"),
        start: format(start, "yyyy-MM-dd' 'HH:mm:ss"),
    };
};
export var GraphGroup = function (_a) {
    var _b, _c, _d, _f, _g, _h, _j, _k, _l, _m, _o;
    var availableParams = _a.availableParams, speciesGroup = _a.speciesGroup, stationUUID = _a.stationUUID, hasRoseChart = _a.hasRoseChart;
    var _p = __read(useState({ data: { items: [], page: 0, total: 0 } }), 2), stationGraph = _p[0], setStationGraph = _p[1];
    var _q = __read(useState({}), 2), roseChartGraph = _q[0], setRoseChartGraph = _q[1];
    var palette = useTheme().palette;
    var _r = useSelector(function (state) { return state.user.info.data; }).settings, aqi_type = _r.aqi_type, timezone = _r.timezone, unitSettings = _r.unit;
    var _s = __read(useState(((_b = availableParams[0]) === null || _b === void 0 ? void 0 : _b.code) || ""), 2), selectedToggleButton = _s[0], setSelectedToggleButton = _s[1];
    var getStationGraph = function (start, end) { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.get("".concat(API_BASE, "/v2/station/").concat(stationUUID, "/parameter/").concat(selectedToggleButton, "/data"), { params: { start: start, end: end } })];
                case 1:
                    result = _a.sent();
                    setStationGraph(result.data);
                    return [2 /*return*/];
            }
        });
    }); };
    var getRoseChartGraph = function (start, end) { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.get("".concat(API_BASE, "/v4/stations/").concat(stationUUID, "/pollutants/").concat(selectedToggleButton, "/pollutant-rose"), { params: { start: start, end: end } })];
                case 1:
                    result = _a.sent();
                    setRoseChartGraph(result.data.data);
                    return [2 /*return*/];
            }
        });
    }); };
    // const {
    // 	data: { stationGraph },
    // 	handlers: { getStationGraph },
    // 	phases: { isGraphLoading },
    // } = useStationGraph();
    // const {
    // 	data: { data: roseChartData },
    // 	isLoading,
    // 	handler: getChart,
    // } = usePollutionRose();
    useEffect(function () {
        var _a = generateTimeFrame(2), start = _a.start, end = _a.end;
        getStationGraph(start, end);
        if (selectedToggleButton !== "AQI" && hasRoseChart) {
            getRoseChartGraph(start, end);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedToggleButton, timezone, stationUUID]);
    var handleButtonGroupChange = function (_e, value) {
        if (value !== selectedToggleButton && value)
            setSelectedToggleButton(value);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var realData = (_f = (_d = (_c = stationGraph === null || stationGraph === void 0 ? void 0 : stationGraph.data) === null || _c === void 0 ? void 0 : _c.items) === null || _d === void 0 ? void 0 : _d.filter(function (item) { return !item.is_predict; })) !== null && _f !== void 0 ? _f : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var predictedData = (_j = (_h = (_g = stationGraph === null || stationGraph === void 0 ? void 0 : stationGraph.data) === null || _g === void 0 ? void 0 : _g.items) === null || _h === void 0 ? void 0 : _h.filter(function (item) { return item.is_predict; })) !== null && _j !== void 0 ? _j : [];
    var isForecast = !!predictedData.length;
    var aqiMax = AQI_SCALE_RANGES[aqi_type].max;
    var unit = (_k = unitSettings[speciesGroup].find(function (species) { return species.code === selectedToggleButton; })) === null || _k === void 0 ? void 0 : _k.value;
    var graphOptions = useMemo(function () {
        var _a;
        var date = new Date();
        var tzOffset = date.getTimezoneOffset();
        var now = +date + tzOffset * 60 * 1000;
        var options = {
            series: [
                {
                    data: __spreadArray(__spreadArray([], __read(realData), false), __read(predictedData), false).map(function (item) { return [
                        +new Date(item.date),
                        +(item === null || item === void 0 ? void 0 : item.value),
                    ]; }),
                    marker: {
                        enabled: false,
                        symbol: "none",
                    },
                    name: "",
                    type: "spline",
                    zoneAxis: "x",
                    zones: [
                        {
                            dashStyle: "Solid",
                            value: ((_a = realData === null || realData === void 0 ? void 0 : realData.at(-1)) === null || _a === void 0 ? void 0 : _a.date)
                                ? +new Date(realData.at(-1).date)
                                : 0,
                        },
                        {
                            dashStyle: "ShortDash",
                        },
                    ],
                },
            ],
            time: {
                timezoneOffset: tzOffset,
                useUTC: true,
            },
            tooltip: {
                borderRadius: 5,
                borderWidth: 0,
                formatter: function () {
                    var isLocalTZ = timezone === "local";
                    var date = isLocalTZ
                        ? convertDateToTimezone(new Date(this.x), timezone)
                        : new Date(this.x);
                    var day = String(date.getDate()).padStart(2, "0");
                    var month = String(date.getMonth() + 1).padStart(2, "0");
                    var year = date.getFullYear();
                    var formattedDate = "".concat(day, "/").concat(month, "/").concat(year);
                    return "\n\t\t\t\t\t\t<div class=\"pod-info-param-graph\" style=\"display: flex; flex-direction: column;\">\n\t\t\t\t\t\t\t<span class=\"value\">".concat(this.y).concat(unit ? "<em class=\"tooltip-unit\"> ".concat(unitLabels(unit), "</em>") : "", "</span>\n\t\t\t\t\t\t\t<span class=\"grey\">\n\t\t\t\t\t\t\t\t").concat(date.toLocaleTimeString(undefined, {
                        hour: "2-digit",
                        hour12: isLocalTZ,
                        minute: "2-digit",
                        second: isLocalTZ ? undefined : "2-digit",
                    }), "\n\t\t\t\t\t\t\t</span>\n\t\t\t\t\t\t\t<span class=\"grey\">\n\t\t\t\t\t\t\t").concat(formattedDate, "\n\t\t\t\t\t\t</span>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t");
                },
                useHTML: true,
            },
            xAxis: {
                max: +addDays(now, isForecast ? 1 : 0),
                min: +subDays(now, 1),
                tickLength: 0,
            },
            yAxis: {
                max: selectedToggleButton === "AQI" ? aqiMax : null,
                plotBands: backGround(selectedToggleButton)
                    ? plotBand(selectedToggleButton, aqi_type)
                    : undefined,
            },
        };
        return options;
    }, [
        realData,
        predictedData,
        isForecast,
        selectedToggleButton,
        aqiMax,
        aqi_type,
        timezone,
        unit,
    ]);
    var filteredAvailableParams = availableParams.filter(function (param) {
        return !BLACKLISTED_PARAMS.includes(param.code);
    });
    if (speciesGroup === "pollutants" &&
        selectedToggleButton !== "AQI" &&
        !unit) {
        unit = (((_l = unitSettings.pollutants[0]) === null || _l === void 0 ? void 0 : _l.value) || "ug/m3");
    }
    var xLabels = [
        {
            text: "-24h",
        },
        {
            text: "NOW",
        },
    ];
    if (isForecast) {
        xLabels.push({
            text: "24h",
        });
    }
    var toggleGroupButtonItems = filteredAvailableParams.map(function (item) { return ({
        text: speciesLabels(item.name),
        value: item.code,
    }); });
    return (_jsxs(Stack, __assign({ width: "100%", gap: "8px", mt: "16px" }, { children: [_jsx(Typography, __assign({ style: {
                    textTransform: "capitalize",
                }, variant: "h5" }, { children: speciesGroup })), _jsx(Tabs, __assign({ allowScrollButtonsMobile: true, draggable: true, scrollButtons: true, centered: false, style: {
                    width: "480px",
                }, value: selectedToggleButton, variant: "scrollable", onChange: handleButtonGroupChange }, { children: toggleGroupButtonItems.map(function (item, index) { return (_jsx(Tab, { label: item.text, value: item.value }, item.value + index)); }) })), _jsx("div", __assign({ style: {
                    position: "relative",
                    width: "100%",
                    height: "100%",
                } }, { children: _jsx(LineChart, { graphOptions: graphOptions, 
                    // isLoading={isGraphLoading}
                    noDataMessage: !((_o = (_m = stationGraph === null || stationGraph === void 0 ? void 0 : stationGraph.data) === null || _m === void 0 ? void 0 : _m.items) === null || _o === void 0 ? void 0 : _o.length)
                        ? "Time series data not available for ".concat(speciesLabels(selectedToggleButton))
                        : "", xLabels: xLabels }) })), hasRoseChart && selectedToggleButton !== "AQI" && (_jsx("div", __assign({ style: {
                    position: "relative",
                    width: "400px",
                    height: "400px",
                    // width: "100%",
                    // height: "100%",
                } }, { children: _jsx(PollutionRose, { color: "#00a7e1", 
                    // data={roseChartData}
                    data: roseChartGraph, fontSize: 12, gridColor: "#8f96a030", height: 0, legendGap: 0, strokeWidth: 1, textColor: "#8f96a0", width: 0 }) })))] })));
};
