var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { MapView, PodMarkers } from "components/organisms";
import { ActionCreators, Maps } from "store";
import { useSelector, useDispatch } from "react-redux";
import { POD_COMPARISON_COLORS } from "theme";
import { Modal } from "components/atoms";
import { StationDetails } from "./stationDetails";
var Map = function () {
    var _a = __read(useState(false), 2), showModal = _a[0], setShowModal = _a[1];
    var _b = __read(useState(null), 2), podUuid = _b[0], setPodUuid = _b[1];
    var userInfo = useSelector(function (state) { return state.user.info.data; });
    var podsById = useSelector(function (state) { return state.pods.byId; });
    var dispatch = useDispatch();
    var pods = useMemo(function () {
        return userInfo.settings.Dashboard.dashboard.sample
            .map(function (item) {
            return podsById[item.uuid];
        })
            .filter(Boolean);
    }, [userInfo, podsById]);
    var podPreview = useSelector(function (state) {
        return state.dashboard.filters.selectPodsMap.view.podPreview;
    });
    return (_jsxs(_Fragment, { children: [_jsx(Modal, __assign({ isOpen: showModal, setIsOpen: setShowModal }, { children: _jsx(StationDetails, { podUuid: podUuid }) })), _jsx(MapView, __assign({ reduxLocation: {
                    path: Maps.PodSelect,
                }, style: {
                    width: "100%",
                    height: "100%",
                }, search: false }, { children: _jsx(PodMarkers, { podsFromProps: pods.reduce(function (prev, curr) {
                        prev[curr.uuid] = curr;
                        return prev;
                    }, {}), aqi_type: userInfo.settings.aqi_type, getHaloColor: function (pod) {
                        return pods.map(function (p) { return p.uuid; }).includes(pod.uuid)
                            ? POD_COMPARISON_COLORS[0].primary
                            : undefined;
                    }, eventHandlers: {
                        click: function (e, pod) {
                            setShowModal(true);
                            setPodUuid(pod.uuid);
                            // dispatch(
                            //     ActionCreators.togglePodSelect({
                            //     name: pod.name,
                            //     uuid: pod.uuid,
                            //     location: pod.location,
                            //     })
                            // );
                        },
                        mouseover: function (e, pod) {
                            if (!podPreview) {
                                dispatch(ActionCreators.setPodPreview(pod, { path: Maps.PodSelect }));
                            }
                        },
                        mouseout: function () {
                            return dispatch(ActionCreators.setPodPreview(null, { path: Maps.PodSelect }));
                        },
                    } }) }))] }));
};
export default Map;
