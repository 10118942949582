var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Modal, Row, IconButton } from "components";
import { HAWidgetSizes } from "models";
import { v4 as uuid } from "uuid";
import styled from "styled-components";
import { COLORS } from "theme";
import { IoCloseOutline } from "react-icons/io5";
import { useDashboardSettings } from "hooks";
import merge from "lodash.merge";
import { ActionCreators } from "store";
import { format } from "date-fns";
import { createMultiViewSection, fetchMultiViewSections, updateMultiViewSection, } from "store/multi-view/api";
import { useMultiViewStore } from "store/multi-view/store";
import { parseTimeToDate } from "utils";
import { timerangeOptions, timeSelectorOptions, } from "../../../../../../constants";
import { SelectPods } from "./SelectPods";
import { SelectSpecies } from "./SelectSpecies";
import SelectDate from "./SelectDate";
import { SelectTimeframe } from "./SelectTimeframe";
import { useViewId } from "hooks/useViewId";
export var BuildGraphSteps;
(function (BuildGraphSteps) {
    BuildGraphSteps[BuildGraphSteps["SelectPods"] = 0] = "SelectPods";
    BuildGraphSteps[BuildGraphSteps["SelectParams"] = 1] = "SelectParams";
    BuildGraphSteps[BuildGraphSteps["SelectDate"] = 2] = "SelectDate";
})(BuildGraphSteps || (BuildGraphSteps = {}));
var Title = styled.h3(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tcolor: ", ";\n\ttext-transform: uppercase;\n\tletter-spacing: 4px;\n\tmargin: 0 0 10px 0;\n\tfont-size: 14px;\n"], ["\n\tcolor: ", ";\n\ttext-transform: uppercase;\n\tletter-spacing: 4px;\n\tmargin: 0 0 10px 0;\n\tfont-size: 14px;\n"])), COLORS.GREY);
var defaultFormatShape = "yyyy-MM-dd' 'HH:mm:ss";
export var formatDateForAPI = function (value) {
    var formatShape = defaultFormatShape;
    var date = typeof value === "number" ? new Date(value) : value;
    return format(date, formatShape);
};
export var BuildRoseModal = function (props) {
    var _a;
    var setIsOpen = props.setIsOpen, edit = props.edit, rest = __rest(props, ["setIsOpen", "edit"]);
    var dispatch = useDispatch();
    var Dashboard = useDashboardSettings();
    var viewUuid = useViewId().selectedView.uuid;
    var _b = useMultiViewStore(), sections = _b.sections, selectedSectionUuid = _b.selectedSectionUuid, handleSections = _b.handleSections;
    var _c = __read(useState(BuildGraphSteps.SelectPods), 2), step = _c[0], setStep = _c[1];
    var _d = __read(useState([]), 2), selectedPods = _d[0], setSelectedPods = _d[1];
    var _e = __read(useState([]), 2), selectedSpecies = _e[0], setSelectedSpecies = _e[1];
    var _f = __read(useState(undefined), 2), selectedDate = _f[0], setSelectedDate = _f[1];
    var _g = __read(useState("frame"), 2), dateFormat = _g[0], setDateFormat = _g[1];
    var _h = __read(useState(undefined), 2), selectedTimeframe = _h[0], setSelectedTimeframe = _h[1];
    var _j = __read(useState(""), 2), customFrameValue = _j[0], setCustomFrameValue = _j[1];
    var getTimeFrame = function () {
        return timerangeOptions.find(function (item) { return item.value === selectedTimeframe; });
    };
    var getDays = function () {
        var _a;
        var timeframe = (_a = getTimeFrame()) === null || _a === void 0 ? void 0 : _a.getTime(undefined);
        if (!timeframe)
            return;
        var startTime = parseTimeToDate(timeframe).getTime();
        var endTime = new Date().getTime();
        var diff = endTime - startTime;
        return Math.floor(diff / (24 * 60 * 60 * 1000));
    };
    useEffect(function () {
        if (edit) {
            setCustomFrameValue(edit.customFrame || "");
            setDateFormat(edit.dateFormat || "frame");
            setSelectedPods(edit.seletectedPods);
            setSelectedSpecies(edit.selectedSpecies);
            setSelectedTimeframe(edit.selectedTimeframe);
        }
    }, [edit]);
    var onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, newWidgetId, newLayoutCell, newLayoutCellsArray, newSettings, response;
        var _a, _b, _c, _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    if (!edit) return [3 /*break*/, 3];
                    return [4 /*yield*/, updateMultiViewSection({
                            uuid: viewUuid,
                            sectionUuid: selectedSectionUuid,
                            body: {
                                name: "".concat(selectedPods.map(function (p) { return p === null || p === void 0 ? void 0 : p.name; }).join(", ")),
                                type: "pollution-rose",
                                config: {
                                    pollutant: selectedSpecies === null || selectedSpecies === void 0 ? void 0 : selectedSpecies[0].species_code,
                                    size: HAWidgetSizes.HALF,
                                    selectedTimeframe: dateFormat === "range" ? null : selectedTimeframe,
                                    station_uuid: (_a = selectedPods === null || selectedPods === void 0 ? void 0 : selectedPods[0]) === null || _a === void 0 ? void 0 : _a.uuid,
                                    widget_id: (_c = (_b = sections.find(function (item) { return item.uuid === selectedSectionUuid; })) === null || _b === void 0 ? void 0 : _b.config) === null || _c === void 0 ? void 0 : _c.widget_id,
                                    days: dateFormat === "range"
                                        ? null
                                        : Number(customFrameValue) || getDays() || 2,
                                    time_format: dateFormat === "frame" ? "fixed" : "calendar",
                                    start_time: dateFormat === "frame"
                                        ? null
                                        : !!(selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.from)
                                            ? formatDateForAPI(selectedDate.from)
                                            : undefined,
                                    end_time: dateFormat === "frame"
                                        ? null
                                        : !!(selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.to)
                                            ? formatDateForAPI(selectedDate.to)
                                            : undefined,
                                    frameShortName: "",
                                },
                            },
                        })];
                case 1:
                    _g.sent();
                    return [4 /*yield*/, fetchMultiViewSections(viewUuid)];
                case 2:
                    response = _g.sent();
                    handleSections(response.data.data);
                    return [3 /*break*/, 6];
                case 3:
                    newWidgetId = uuid();
                    newLayoutCell = { x: 1, y: 1, w: 2, h: 4, i: newWidgetId };
                    newLayoutCellsArray = ((_d = Dashboard === null || Dashboard === void 0 ? void 0 : Dashboard.analytics) === null || _d === void 0 ? void 0 : _d.layout)
                        ? __spreadArray(__spreadArray([], __read((_e = Dashboard === null || Dashboard === void 0 ? void 0 : Dashboard.analytics) === null || _e === void 0 ? void 0 : _e.layout), false), [newLayoutCell], false) : [newLayoutCell];
                    newSettings = merge({}, { Dashboard: Dashboard }, {
                        Dashboard: {
                            analytics: {
                                widgets: [],
                                layout: newLayoutCellsArray,
                            },
                        },
                    });
                    dispatch(ActionCreators.updateSettings(newSettings));
                    return [4 /*yield*/, createMultiViewSection({
                            uuid: viewUuid,
                            body: {
                                name: "".concat(selectedPods.map(function (p) { return p === null || p === void 0 ? void 0 : p.name; }).join(", ")),
                                type: "pollution-rose",
                                config: {
                                    pollutant: selectedSpecies === null || selectedSpecies === void 0 ? void 0 : selectedSpecies[0].species_code,
                                    size: HAWidgetSizes.HALF,
                                    selectedTimeframe: dateFormat === "range" ? null : selectedTimeframe,
                                    station_uuid: (_f = selectedPods === null || selectedPods === void 0 ? void 0 : selectedPods[0]) === null || _f === void 0 ? void 0 : _f.uuid,
                                    widget_id: newWidgetId,
                                    days: dateFormat === "range"
                                        ? null
                                        : Number(customFrameValue) || getDays() || 2,
                                    time_format: dateFormat === "frame" ? "fixed" : "calendar",
                                    start_time: dateFormat === "frame"
                                        ? null
                                        : !!(selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.from)
                                            ? formatDateForAPI(selectedDate.from)
                                            : undefined,
                                    end_time: dateFormat === "frame"
                                        ? null
                                        : !!(selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.to)
                                            ? formatDateForAPI(selectedDate.to)
                                            : undefined,
                                    frameShortName: "",
                                },
                            },
                        })];
                case 4:
                    _g.sent();
                    return [4 /*yield*/, fetchMultiViewSections(viewUuid)];
                case 5:
                    response = _g.sent();
                    handleSections(response.data.data);
                    _g.label = 6;
                case 6:
                    setIsOpen(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var steps = (_a = {},
        _a[BuildGraphSteps.SelectPods] = (_jsx(SelectPods, { selectedPods: selectedPods, setSelectedPods: setSelectedPods, setStep: setStep })),
        _a[BuildGraphSteps.SelectDate] = (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ style: {
                        maxWidth: "320px",
                        padding: "10px",
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                    } }, { children: [_jsx("span", { children: "Date format:" }), _jsx(Select, { onChange: function (option) {
                                return setDateFormat(option.value);
                            }, value: [
                                timeSelectorOptions.find(function (item) { return item.value === dateFormat; }),
                            ], options: timeSelectorOptions })] })), dateFormat === "range" && (_jsx(SelectDate, { selectedSpecies: selectedSpecies, setStep: setStep, editMode: !!edit, setSelectedDate: setSelectedDate, onSubmit: onSubmit })), dateFormat === "frame" && (_jsx(SelectTimeframe, { selectedTimeframe: selectedTimeframe, setStep: setStep, setSelectedTimeframe: setSelectedTimeframe, onSubmit: onSubmit, customFrameValue: customFrameValue, setCustomFrameValue: setCustomFrameValue, editMode: !!edit }))] })),
        _a[BuildGraphSteps.SelectParams] = (_jsx(SelectSpecies, { selectedPods: selectedPods, selectedSpecies: selectedSpecies, setSelectedSpecies: setSelectedSpecies, setStep: setStep })),
        _a);
    return (_jsxs(Modal, __assign({ setIsOpen: setIsOpen, id: "build-ha-graph-modal" }, rest, { wrapperStyle: {
            minWidth: "800px",
            maxWidth: "1000px",
            minHeight: "400px",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "space-between",
        }, afterClose: function () {
            setStep(BuildGraphSteps.SelectPods);
            setSelectedPods([]);
            setSelectedSpecies([]);
            setSelectedDate(undefined);
        } }, { children: [_jsxs(Row, { children: [_jsx(Title, { children: "Build Pollution Rose" }), _jsx(IconButton, __assign({ size: 40, onClick: function () { return setIsOpen(false); } }, { children: _jsx(IoCloseOutline, {}) }))] }), steps[step]] })));
};
var templateObject_1;
