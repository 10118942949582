var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiMapAlt } from "react-icons/bi";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { Row, Button, IconButton, Dropdown, Tooltip, SelectTimeframeDropdown, } from "components";
import styled from "styled-components";
import { ActionCreators } from "store";
import { TimeRanges } from "models";
import { COLORS } from "theme";
import { useDashboardSettings, useDidMountEffect } from "hooks";
import { nowUTC } from "utils";
import clsx from "clsx";
import { MapSelect } from "./MapSelect";
import { usePodSelectLogic } from "./usePodSelectLogic";
import { timerangeOptions } from "../../../../constants";
import { PodSelectDropdown } from "./PodSelectDropdown";
export var SelectWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmin-width: 100px;\n\tflex: 1;\n\tmargin-right: 10px;\n"], ["\n\tmin-width: 100px;\n\tflex: 1;\n\tmargin-right: 10px;\n"])));
var ApplyButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\theight: unset;\n\tmin-height: 40px;\n\tpadding: 0 12px;\n\tmargin: 0 10px 0 40px;\n\n\t&.change {\n\t\tbackground-color: ", ";\n\t\tborder: 1px solid ", ";\n\t}\n\n\t&.error {\n\t\tbackground-color: ", ";\n\t\tborder: 1px solid ", ";\n\t\tpointer-events: none;\n\t\topacity: 0.8;\n\t}\n"], ["\n\theight: unset;\n\tmin-height: 40px;\n\tpadding: 0 12px;\n\tmargin: 0 10px 0 40px;\n\n\t&.change {\n\t\tbackground-color: ", ";\n\t\tborder: 1px solid ", ";\n\t}\n\n\t&.error {\n\t\tbackground-color: ", ";\n\t\tborder: 1px solid ", ";\n\t\tpointer-events: none;\n\t\topacity: 0.8;\n\t}\n"])), COLORS.ORANGE, COLORS.ORANGE, COLORS.RED, COLORS.RED);
var WarningIconWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tposition: relative;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\theight: 16px;\n\twidth: 0px;\n\tmargin-left: 0;\n\topacity: 0;\n\toverflow: hidden;\n\ttransition: all 200ms;\n\n\t&.visible {\n\t\topacity: 1;\n\t\twidth: 16px;\n\t\tmargin-left: 6px;\n\t\ttransition: all 200ms;\n\t}\n"], ["\n\tposition: relative;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\theight: 16px;\n\twidth: 0px;\n\tmargin-left: 0;\n\topacity: 0;\n\toverflow: hidden;\n\ttransition: all 200ms;\n\n\t&.visible {\n\t\topacity: 1;\n\t\twidth: 16px;\n\t\tmargin-left: 6px;\n\t\ttransition: all 200ms;\n\t}\n"])));
var WarningIcon = styled(BsFillInfoCircleFill)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tfill: ", ";\n\tposition: absolute;\n"], ["\n\tfill: ", ";\n\tposition: absolute;\n"])), COLORS.WHITE);
var WarningIconBG = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\tbackground-color: ", ";\n\tborder-radius: 50%;\n\theight: 90%;\n\twidth: 90%;\n\tposition: absolute;\n"], ["\n\tbackground-color: ", ";\n\tborder-radius: 50%;\n\theight: 90%;\n\twidth: 90%;\n\tposition: absolute;\n"])), COLORS.RED);
/**
 * Custom component to be fed to react-select Dropdown to take over rendering
 * of the currently selected value in the dropdown to instead render a list
 * of current selections, with an ellipsis (...) if the list gets cut off
 */
export var MultiValueLabel = function (props) {
    if (props.data.value !== props.selectProps.value[0].value)
        return null;
    if (props.selectProps.menuIsOpen &&
        props.selectProps.value.length) {
        if (props.selectProps.inputValue)
            return null;
        return (_jsx("div", __assign({ style: {
                position: "absolute",
                top: "4px",
                left: "10px",
                opacity: 0.5,
                fontStyle: "italic",
            } }, { children: props.selectProps.placeholder })));
    }
    return (_jsx("div", __assign({}, props.innerProps, { className: "custom-multivalue", style: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        } }, { children: props.selectProps.value
            .map(function (p) { return p.label; })
            .join(", ") })));
};
/**
 * Custom component to not render a remove 'X' in the current selection in react-select
 */
export var MultiValueRemove = function () { return null; };
/**
 * Filter dropdowns on Dashboard page that determine what data to show in the rest of the dashboard
 */
export var SelectHeader = function () {
    var _a, _b, _c, _d, _e;
    var Dashboard = useDashboardSettings();
    var dispatch = useDispatch();
    var pods = useSelector(function (state) { return state.pods.byId; });
    var customTimeframe = useSelector(function (state) { return state.dashboard.filters.timeframe; });
    var countryLoading = useSelector(function (state) {
        return state.dashboard.filters.available.country.loading;
    });
    var stateLoading = useSelector(function (state) {
        return state.dashboard.filters.available.state.loading;
    });
    var cityLoading = useSelector(function (state) { return state.dashboard.filters.available.city.loading; });
    var _f = __read(useState(false), 2), mapSelectOpen = _f[0], setMapSelectOpen = _f[1];
    var _g = __read(useState((_a = timerangeOptions.find(function (range) { var _a; return range.value === ((_a = Dashboard === null || Dashboard === void 0 ? void 0 : Dashboard.dashboard) === null || _a === void 0 ? void 0 : _a.timerange); })) !== null && _a !== void 0 ? _a : timerangeOptions.find(function (range) { return range.value === TimeRanges.PAST_MONTH; })), 2), selectedTimerange = _g[0], setSelectedTimerange = _g[1];
    /**
     * When user info comes in, register their saved timeframe settings in the store, if any
     */
    useEffect(function () {
        var _a;
        if ((_a = Dashboard === null || Dashboard === void 0 ? void 0 : Dashboard.dashboard) === null || _a === void 0 ? void 0 : _a.timerange) {
            var chosenTimerangeOption = timerangeOptions.find(function (range) { return range.value === Dashboard.dashboard.timerange; });
            dispatch(ActionCreators.setTimeframe([
                chosenTimerangeOption.getTime(),
                nowUTC(),
            ]));
        }
    }, [(_b = Dashboard === null || Dashboard === void 0 ? void 0 : Dashboard.dashboard) === null || _b === void 0 ? void 0 : _b.timerange]);
    /**
     * When user changes timeframe, register the change in the store
     */
    useDidMountEffect(function () {
        dispatch(ActionCreators.setTimeframe([selectedTimerange.getTime(), nowUTC()]));
    }, [selectedTimerange.value]);
    var _h = usePodSelectLogic(), getLocationsFromSample = _h.getLocationsFromSample, availableCountries = _h.availableCountries, availableStates = _h.availableStates, availableCities = _h.availableCities, availablePods = _h.availablePods, selectedCountries = _h.selectedCountries, setSelectedCountries = _h.setSelectedCountries, selectedStates = _h.selectedStates, setSelectedStates = _h.setSelectedStates, selectedCities = _h.selectedCities, setSelectedCities = _h.setSelectedCities, selectedPods = _h.selectedPods, sampleLoaded = _h.sampleLoaded;
    /**
     * If user has changed the pod selection but has not yet hit 'Apply'
     */
    var selectionChangePending = sampleLoaded &&
        selectedPods
            .map(function (p) { return p.uuid; })
            .sort()
            .join(",") !==
            ((_c = Dashboard === null || Dashboard === void 0 ? void 0 : Dashboard.dashboard) === null || _c === void 0 ? void 0 : _c.sample.filter(function (p) { return p; }).map(function (p) { return p.uuid; }).sort().join(","));
    /**
     * If user has selected more than the limit
     */
    var tooManyPodsSelected = selectedPods.length > 100;
    /**
     * If user has changed the time range but has not yet hit 'Apply'
     */
    var timerangeChangePending = selectedTimerange.value === TimeRanges.CUSTOM
        ? // If using a custom timeframe, stringify the timeframe arrays and compare
            JSON.stringify(customTimeframe) ===
                JSON.stringify((_d = Dashboard === null || Dashboard === void 0 ? void 0 : Dashboard.dashboard) === null || _d === void 0 ? void 0 : _d.timerange)
        : // Otherwise, just compare the enum values
            selectedTimerange.value !== ((_e = Dashboard === null || Dashboard === void 0 ? void 0 : Dashboard.dashboard) === null || _e === void 0 ? void 0 : _e.timerange);
    var messageNoun = selectionChangePending && !timerangeChangePending
        ? "selection"
        : !selectionChangePending && timerangeChangePending
            ? "time frame"
            : "selection and timeframe";
    /**
     * Callback for when the user hits the apply button
     */
    var handleApply = function () {
        var newSettings = { Dashboard: __assign({}, Dashboard) };
        newSettings.Dashboard.dashboard.sample = selectedPods;
        newSettings.Dashboard.dashboard.timerange = selectedTimerange.value;
        /**
         * Update the user's settings to the new selection
         */
        dispatch(ActionCreators.updateSettings(newSettings));
        if (selectedPods.length) {
            // Call to get statistics for the stats cards
            dispatch(ActionCreators.requestTimeframeStats());
            // Call to get pod summaries for any pods not already in the store
            dispatch(ActionCreators.requestPodSummaries(selectedPods.map(function (pod) { return pod.uuid; }).filter(function (pod) { return !pods[pod]; })));
        }
        else {
            /**
             * If user has cleared their selection, we also clear out all the location dropdowns
             */
            setSelectedCountries({ call: false, selected: [] });
            setSelectedStates({ call: false, selected: [] });
            setSelectedCities({ call: false, selected: [] });
        }
    };
    return (_jsxs(Row, __assign({ justifyContent: "flex-start", alignItems: "stretch" }, { children: [_jsx(SelectWrapper, { children: _jsx(Dropdown, { placeholder: "Country", id: "select-country-dropdown", classNamePrefix: "select-country", isMulti: true, selectAll: true, loading: countryLoading, options: availableCountries, value: selectedCountries.selected, components: { MultiValueLabel: MultiValueLabel, MultiValueRemove: MultiValueRemove }, onChange: function (e) {
                        return setSelectedCountries({ call: true, selected: e });
                    } }) }), _jsx(SelectWrapper, { children: _jsx(Dropdown, { placeholder: "Region", id: "select-region-dropdown", classNamePrefix: "select-region", isMulti: true, selectAll: true, isDisabled: !availableStates.length, loading: stateLoading, options: availableStates, value: selectedStates.selected, components: { MultiValueLabel: MultiValueLabel, MultiValueRemove: MultiValueRemove }, onChange: function (e) {
                        return setSelectedStates({ call: true, selected: e });
                    } }) }), _jsx(SelectWrapper, { children: _jsx(Dropdown, { placeholder: "City", id: "select-city-dropdown", classNamePrefix: "select-city", isMulti: true, selectAll: true, isDisabled: !availableCities.length, loading: cityLoading, options: availableCities, value: selectedCities.selected, components: { MultiValueLabel: MultiValueLabel, MultiValueRemove: MultiValueRemove }, onChange: function (e) {
                        return setSelectedCities({ call: true, selected: e });
                    } }) }), _jsx(SelectWrapper, { children: _jsx(PodSelectDropdown, { availablePods: availablePods, selectedPods: selectedPods }) }), _jsx(SelectWrapper, __assign({ className: "date-select" }, { children: _jsx(SelectTimeframeDropdown, { value: selectedTimerange, onChange: function (e) { return setSelectedTimerange(e); } }) })), _jsx(IconButton, __assign({ outline: "filled", size: 40, onClick: function () { return setMapSelectOpen(true); }, id: "select-map-modal-button" }, { children: _jsx(BiMapAlt, {}) })), _jsx(Tooltip, __assign({ id: "selection-changed-icon", contents: tooManyPodsSelected
                    ? "You have selected too many sensors - the maximum is 100.  Please revise your selection."
                    : "Your ".concat(messageNoun, " has changed.  Click 'Apply' to apply your new ").concat(messageNoun, " to the dashboard"), place: "bottom", offset: { left: 80 }, disabled: !selectionChangePending &&
                    !timerangeChangePending &&
                    !tooManyPodsSelected, arrowColor: "transparent" }, { children: _jsx("div", { children: _jsxs(ApplyButton, __assign({ onClick: handleApply, outline: "filled", className: clsx({
                            change: selectionChangePending || timerangeChangePending,
                            error: tooManyPodsSelected,
                        }), id: "apply-changes-button" }, { children: ["Apply", _jsxs(WarningIconWrapper, __assign({ className: (selectionChangePending || timerangeChangePending) && "visible" }, { children: [_jsx(WarningIconBG, {}), _jsx(WarningIcon, { size: 16 })] }))] })) }) })), _jsx(Button, __assign({ onClick: function () { return dispatch(ActionCreators.clearSelectedPods()); }, outline: "filled", style: { height: "unset", padding: "0 12px" }, id: "clear-changes-button" }, { children: "Clear" })), _jsx(MapSelect, { mapSelectOpen: mapSelectOpen, setMapSelectOpen: setMapSelectOpen, getLocationsFromSample: getLocationsFromSample })] })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
