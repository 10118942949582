var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { BiMapAlt } from "react-icons/bi";
import { AiOutlineClear, AiOutlineStock } from "react-icons/ai";
import { MdOutlineDashboard } from "react-icons/md";
import styled, { css } from "styled-components";
import { Row, Button } from "components";
import { COLORS } from "theme";
import { useDashboardSettings } from "hooks";
import { useMultiViewStore } from "store/multi-view/store";
import { fetchViews } from "store/multi-view/api";
import { BuildGraphModal } from "./widgets";
import { BuildMapModal } from "./widgets/maps";
import { BuildRoseModal } from "./widgets/graphs/BuildRoseModal";
import { ManageViews } from "./ManageViews";
import { useViewId } from "hooks/useViewId";
import merge from "lodash/merge";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators } from "store";
var BigButtonContainer = styled(Row)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\topacity: 1;\n\ttransition: opacity 250ms;\n\theight: 260px;\n\n\t", "\n"], ["\n\topacity: 1;\n\ttransition: opacity 250ms;\n\theight: 260px;\n\n\t", "\n"])), function (props) {
    return props.hidden && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\theight: 0px;\n\t\t\topacity: 0;\n\t\t\tmargin-top: 0px;\n\t\t\ttransition: opacity 250ms;\n\t\t\toverflow: hidden;\n\t\t"], ["\n\t\t\theight: 0px;\n\t\t\topacity: 0;\n\t\t\tmargin-top: 0px;\n\t\t\ttransition: opacity 250ms;\n\t\t\toverflow: hidden;\n\t\t"])));
});
export var NewWidgetButton = styled(Button)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tflex: 1;\n\tmin-height: 260px;\n\tborder-color: ", ";\n\tbackground-color: #ffffff;\n\tcolor: ", ";\n\tborder-radius: 10px;\n\tmin-width: 40%;\n\ttransition: all 200ms;\n\n\t.prompt {\n\t\ttransition: all 200ms;\n\t}\n\n\topacity: ", ";\n\tcursor: ", ";\n\t&:hover {\n\t\tbox-shadow: ", ";\n\t}\n\n\t&:first-child {\n\t\tmargin-right: 30px;\n\t}\n\n\t& * {\n\t\tpointer-events: none;\n\t}\n\n\t", "\n"], ["\n\tflex: 1;\n\tmin-height: 260px;\n\tborder-color: ", ";\n\tbackground-color: #ffffff;\n\tcolor: ", ";\n\tborder-radius: 10px;\n\tmin-width: 40%;\n\ttransition: all 200ms;\n\n\t.prompt {\n\t\ttransition: all 200ms;\n\t}\n\n\topacity: ", ";\n\tcursor: ", ";\n\t&:hover {\n\t\tbox-shadow: ", ";\n\t}\n\n\t&:first-child {\n\t\tmargin-right: 30px;\n\t}\n\n\t& * {\n\t\tpointer-events: none;\n\t}\n\n\t", "\n"])), COLORS.LIGHTGREY, COLORS.BLACK, function (props) { return props.disabled && 0.6; }, function (props) { return props.disabled && "auto"; }, function (props) { return props.disabled && "none"; }, function (props) {
    return props.selected && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t\t\tbackground-color: ", ";\n\t\t\tcolor: ", ";\n\t\t\ttransition: all 200ms;\n\n\t\t\t.prompt {\n\t\t\t\tcolor: ", ";\n\t\t\t\ttransition: all 200ms;\n\t\t\t}\n\t\t"], ["\n\t\t\tbackground-color: ", ";\n\t\t\tcolor: ", ";\n\t\t\ttransition: all 200ms;\n\n\t\t\t.prompt {\n\t\t\t\tcolor: ", ";\n\t\t\t\ttransition: all 200ms;\n\t\t\t}\n\t\t"])), COLORS.BLUE, COLORS.WHITE, COLORS.WHITE);
});
var ButtonFake = styled(Row)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\tbackground-color: ", ";\n\tcolor: ", ";\n\tpadding: 6px 12px;\n\tborder-radius: 5px;\n\tmargin: 20px 0 26px 0;\n\tfont-size: 16px;\n"], ["\n\tbackground-color: ", ";\n\tcolor: ", ";\n\tpadding: 6px 12px;\n\tborder-radius: 5px;\n\tmargin: 20px 0 26px 0;\n\tfont-size: 16px;\n"])), COLORS.BLUE, COLORS.WHITE);
var ToolbarCOntainer = styled(Row)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\tmargin: -32px -32px 0 -32px;\n\tborder-top: 1px solid ", ";\n\tborder-bottom: 1px solid ", ";\n\tbackground-color: ", ";\n\topacity: 1;\n\ttransition: opacity 250ms;\n\theight: 52px;\n\tpadding: 0 32px;\n\talign-items: stretch;\n\n\t", "\n"], ["\n\tmargin: -32px -32px 0 -32px;\n\tborder-top: 1px solid ", ";\n\tborder-bottom: 1px solid ", ";\n\tbackground-color: ", ";\n\topacity: 1;\n\ttransition: opacity 250ms;\n\theight: 52px;\n\tpadding: 0 32px;\n\talign-items: stretch;\n\n\t", "\n"])), COLORS.LIGHTGREY, COLORS.LIGHTGREY, COLORS.WHITE, function (props) {
    return props.hidden && css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\t\t\tmargin: 0 -32px 0 -32px;\n\t\t\theight: 0px;\n\t\t\topacity: 0;\n\t\t\ttransition: opacity 250ms;\n\t\t\toverflow: hidden;\n\t\t"], ["\n\t\t\tmargin: 0 -32px 0 -32px;\n\t\t\theight: 0px;\n\t\t\topacity: 0;\n\t\t\ttransition: opacity 250ms;\n\t\t\toverflow: hidden;\n\t\t"])));
});
var ToolbarButton = styled(Button)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n\tborder: none;\n\tpadding: 0 24px;\n\tcolor: ", ";\n\theight: 100%;\n\n\t&.active {\n\t\tcolor: ", ";\n\t}\n\n\t&:first-child {\n\t\tpadding: 0 16px;\n\t}\n\n\t& span {\n\t\tmargin-left: 10px;\n\t}\n"], ["\n\tborder: none;\n\tpadding: 0 24px;\n\tcolor: ", ";\n\theight: 100%;\n\n\t&.active {\n\t\tcolor: ", ";\n\t}\n\n\t&:first-child {\n\t\tpadding: 0 16px;\n\t}\n\n\t& span {\n\t\tmargin-left: 10px;\n\t}\n"])), COLORS.BLACKISH, COLORS.WHITE);
/**
 * Component to render a toolbar or buttons to build new widgets.  Contains
 * all controls to help user create / delete / move widgets around
 */
export var BuildWidgetButtons = function (_a) {
    var _b, _c, _d, _e;
    var editMode = _a.editMode, setEditMode = _a.setEditMode, setIsClearAllOpen = _a.setIsClearAllOpen;
    var _f = useMultiViewStore(), views = _f.views, handleViews = _f.handleViews;
    var _g = useViewId(), selectedView = _g.selectedView, setSelectedView = _g.setSelectedView;
    var Dashboard = useDashboardSettings();
    var dispatch = useDispatch();
    function handleView(uuid) {
        setSelectedView(uuid);
        var newSettings = merge({}, { Dashboard: Dashboard }, {
            Dashboard: {
                analytics: {
                    selectedViewId: uuid,
                },
            },
        });
        dispatch(ActionCreators.updateSettings(newSettings));
    }
    var refetchSection = function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/];
    }); }); };
    var userInfo = useSelector(function (state) { return state.user.info.data; });
    var _h = __read(useState(false), 2), createGraphOpen = _h[0], setCreateGraphOpen = _h[1];
    var _j = __read(useState(false), 2), createRoseOpen = _j[0], setCreateRoseOpen = _j[1];
    var _k = __read(useState(false), 2), createMapOpen = _k[0], setCreateMapOpen = _k[1];
    var _l = __read(useState(false), 2), showViewModal = _l[0], setShowViewModal = _l[1];
    var handleFetchViews = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, fetchViews()];
                case 1:
                    response = _b.sent();
                    handleViews((_a = response.data.data) !== null && _a !== void 0 ? _a : []);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _b.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        handleFetchViews();
    }, []);
    var viewId = (_d = (_c = (_b = userInfo === null || userInfo === void 0 ? void 0 : userInfo.settings) === null || _b === void 0 ? void 0 : _b.Dashboard) === null || _c === void 0 ? void 0 : _c.analytics) === null || _d === void 0 ? void 0 : _d.selectedViewId;
    useEffect(function () {
        if (viewId && views.some(function (item) { return item.uuid === viewId; }))
            setSelectedView(viewId);
    }, [viewId, views]);
    var handlePreOpenModal = function () {
        if (!selectedView.uuid) {
            toast.warning("First ".concat(views.length ? "select" : "create", " a view"));
            if (!views.length)
                setShowViewModal(true);
            return false;
        }
        return true;
    };
    return (_jsxs(_Fragment, { children: [_jsxs(ToolbarCOntainer, __assign({ hidden: false }, { children: [_jsxs(Row, __assign({ alignItems: "stretch" }, { children: [_jsxs(ToolbarButton, __assign({ onClick: function () {
                                    var result = handlePreOpenModal();
                                    result && setCreateGraphOpen(true);
                                }, id: "build-graph-toolbar-button" }, { children: [_jsx(AiOutlineStock, { size: 20, color: COLORS.BLACKISH }), _jsx("span", { children: "Build a Graph" })] })), _jsxs(ToolbarButton, __assign({ onClick: function () {
                                    var result = handlePreOpenModal();
                                    result && setCreateRoseOpen(true);
                                }, id: "build-map-toolbar-button" }, { children: [_jsx(BiMapAlt, { size: 20, color: COLORS.BLACKISH }), _jsx("span", { children: "Build Pollution Rose" })] })), _jsxs(ToolbarButton, __assign({ onClick: function () {
                                    var result = handlePreOpenModal();
                                    result && setCreateMapOpen(true);
                                }, id: "build-map-toolbar-button" }, { children: [_jsx(BiMapAlt, { size: 20, color: COLORS.BLACKISH }), _jsx("span", { children: "Build a Map" })] }))] })), _jsxs("div", __assign({ style: { display: "flex", gap: "8px" } }, { children: [_jsxs("div", __assign({ style: { display: "flex", alignItems: "center", gap: "16px" } }, { children: [_jsx("div", __assign({ style: { minWidth: "120px" } }, { children: _jsx(Select, { placeholder: "Select view", className: "test-test", onChange: function (option) { return handleView(option.value); }, value: [
                                                selectedView
                                                    ? {
                                                        label: (_e = views.find(function (item) { return item.uuid === selectedView.uuid; })) === null || _e === void 0 ? void 0 : _e.name,
                                                        value: selectedView.uuid,
                                                    }
                                                    : null,
                                            ], options: views.map(function (item) { return ({
                                                label: item.name,
                                                value: item.uuid,
                                            }); }) }) })), _jsxs(ToolbarButton, __assign({ onClick: function () { return setShowViewModal(true); }, id: "build-map-toolbar-button" }, { children: [_jsx(BiMapAlt, { size: 20, color: COLORS.BLACKISH }), _jsx("span", { children: "Manage Views" })] }))] })), _jsx(ToolbarButton, __assign({ onClick: function () {
                                    var result = handlePreOpenModal();
                                    result && setIsClearAllOpen(function (m) { return !m; });
                                } }, { children: _jsxs("div", __assign({ style: {
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    } }, { children: [_jsx(AiOutlineClear, { size: 20, color: editMode ? COLORS.WHITE : COLORS.BLACKISH }), _jsx("span", { children: "Clear" })] })) })), _jsx(ToolbarButton, __assign({ outline: editMode ? "filled" : undefined, className: editMode && "active", onClick: function () {
                                    var result = handlePreOpenModal();
                                    result && setEditMode(function (m) { return !m; });
                                } }, { children: _jsxs("div", __assign({ style: {
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    } }, { children: [_jsx(MdOutlineDashboard, { size: 20, color: editMode ? COLORS.WHITE : COLORS.BLACKISH }), _jsx("span", { children: "Edit Layout" })] })) }))] }))] })), _jsx(ManageViews, { isOpen: showViewModal, setIsOpen: setShowViewModal }), _jsx(BuildGraphModal, { isOpen: createGraphOpen, setIsOpen: setCreateGraphOpen, refetchSection: refetchSection }), _jsx(BuildRoseModal, { isOpen: createRoseOpen, setIsOpen: setCreateRoseOpen }), _jsx(BuildMapModal, { isOpen: createMapOpen, setIsOpen: setCreateMapOpen })] }));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
