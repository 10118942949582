var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ImShrink2, ImEnlarge2 } from "react-icons/im";
import { TbDragDrop } from "react-icons/tb";
import { MdDeleteOutline } from "react-icons/md";
import { Shake } from "reshake";
import { Card, IconButton, Tooltip, Prompt, ConfirmationModal, Column, Row, } from "components";
import { HAWidgetSizes } from "models";
import { BiEditAlt, BiDownload } from "react-icons/bi";
import styled from "styled-components";
import { COLORS } from "theme";
import clsx from "clsx";
import { useDashboardSettings } from "hooks";
import { ActionCreators } from "store";
import { PeCircleLogoWhite } from "assets/images";
import { useMultiViewStore } from "store/multi-view/store";
import { deleteMultiViewSection, fetchMultiViewSections, } from "store/multi-view/api";
import { useViewId } from "hooks/useViewId";
var DraggableContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tposition: relative;\n\twidth: 100%;\n\theight: 100%;\n\n\t/**\n   * This classname is applied only when saving to png, as html2canvas has issues\n   * rendering box shadows properly: https://github.com/niklasvh/html2canvas/issues/1856\n   * Will remove all box shadows for any elements that are within the DOM of the widget,\n   * And replace them with simple 1px grey borders\n   */\n\t.no-box-shadows & {\n\t\t* {\n\t\t\tbox-shadow: none !important;\n\t\t}\n\t}\n"], ["\n\tposition: relative;\n\twidth: 100%;\n\theight: 100%;\n\n\t/**\n   * This classname is applied only when saving to png, as html2canvas has issues\n   * rendering box shadows properly: https://github.com/niklasvh/html2canvas/issues/1856\n   * Will remove all box shadows for any elements that are within the DOM of the widget,\n   * And replace them with simple 1px grey borders\n   */\n\t.no-box-shadows & {\n\t\t* {\n\t\t\tbox-shadow: none !important;\n\t\t}\n\t}\n"])));
var InnerCardWrapper = styled(Card)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\theight: 100%;\n\tpadding-top: 20px;\n\ttransition: all 200ms;\n\n\t.HALF & {\n\t\tpadding-top: 8px;\n\t\ttransition: all 200ms;\n\t}\n"], ["\n\theight: 100%;\n\tpadding-top: 20px;\n\ttransition: all 200ms;\n\n\t.HALF & {\n\t\tpadding-top: 8px;\n\t\ttransition: all 200ms;\n\t}\n"])));
/**
 * The edit overlay which contains all the edit mode functionality icons
 * (resize, delete, drag, etc)
 */
var Overlay = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tposition: absolute;\n\ttop: 0;\n\tbottom: 0;\n\tleft: 0;\n\tright: 0;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tbackground-color: ", "B3;\n\topacity: 0;\n\tborder-radius: 16px;\n\tpointer-events: none;\n\ttransition: all 300ms;\n\n\t.drag-message {\n\t\topacity: 0;\n\t\ttransition: all 300ms;\n\t}\n\n\t&:hover .drag-message {\n\t\topacity: 1;\n\t\ttransition: all 300ms;\n\t}\n\n\t.edit-mode & {\n\t\topacity: 1;\n\t\tpointer-events: auto;\n\t\ttransition: all 300ms;\n\t\tcursor: grab;\n\n\t\t&.grabbing {\n\t\t\tcursor: grabbing;\n\t\t}\n\t}\n"], ["\n\tposition: absolute;\n\ttop: 0;\n\tbottom: 0;\n\tleft: 0;\n\tright: 0;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tbackground-color: ", "B3;\n\topacity: 0;\n\tborder-radius: 16px;\n\tpointer-events: none;\n\ttransition: all 300ms;\n\n\t.drag-message {\n\t\topacity: 0;\n\t\ttransition: all 300ms;\n\t}\n\n\t&:hover .drag-message {\n\t\topacity: 1;\n\t\ttransition: all 300ms;\n\t}\n\n\t.edit-mode & {\n\t\topacity: 1;\n\t\tpointer-events: auto;\n\t\ttransition: all 300ms;\n\t\tcursor: grab;\n\n\t\t&.grabbing {\n\t\t\tcursor: grabbing;\n\t\t}\n\t}\n"])), COLORS.WHITE);
var ActionButton = styled(IconButton)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tposition: absolute;\n\tborder: 1px solid ", ";\n\tbackground-color: ", ";\n"], ["\n\tposition: absolute;\n\tborder: 1px solid ", ";\n\tbackground-color: ", ";\n"])), COLORS.GREY, COLORS.WHITE);
var Logo = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\theight: 38px;\n\tmargin-top: -18px;\n"], ["\n\theight: 38px;\n\tmargin-top: -18px;\n"])));
/**
 * Wrapper for all widgets on the Historics and Analytics pages.  provides common functionality,
 * like basic styling, control of classNames associated with the size of the widget, drag and drop
 * functionality, jitter animations in edit mode, etc.
 */
export var WidgetWrapper = React.forwardRef(function (props, ref) {
    var _a;
    var sectionUuid = props.sectionUuid, size = props.size, setSize = props.setSize, editMode = props.editMode, children = props.children, widget = props.widget, setEditOpen = props.setEditOpen, rest = __rest(props, ["sectionUuid", "size", "setSize", "editMode", "children", "widget", "setEditOpen"]);
    var isFullSize = size === HAWidgetSizes.FULL;
    var SizeIcon = isFullSize ? ImShrink2 : ImEnlarge2;
    var _b = useMultiViewStore(), handleSelectedSectionUuid = _b.handleSelectedSectionUuid, selectedSectionUuid = _b.selectedSectionUuid, handleSections = _b.handleSections;
    var selectedView = useViewId().selectedView;
    var dispatch = useDispatch();
    var Dashboard = useDashboardSettings();
    var isLoadingSettings = useSelector(function (state) { return state.user.info.data.settings.loading; });
    var _c = __read(useState(false), 2), grabbing = _c[0], setGrabbing = _c[1];
    var _d = __read(useState(false), 2), deleteConfirmOpen = _d[0], setDeleteConfirmOpen = _d[1];
    /**
     * Callback to update the name of the widget
     */
    return (_jsxs(_Fragment, { children: [_jsxs(DraggableContainer, __assign({ className: clsx(size, (_a = {}, _a["edit-mode"] = editMode, _a)), ref: ref }, { children: [_jsx(Shake, __assign({ h: 2, v: 2, r: props.exportMode ? 0 : 0.1, dur: 700, int: 10, max: 100, fixed: true, fixedStop: false, active: editMode && !grabbing, style: { height: "100%" } }, { children: _jsxs(InnerCardWrapper, __assign({}, __assign(__assign({}, rest), { loading: isLoadingSettings }), { children: [_jsxs(Row, __assign({ justifyContent: "flex-start" }, { children: [_jsx("h2", __assign({ style: { margin: "0 0 8px 0", paddingBottom: "3px", flex: 1 }, className: "one-line-ellipsis graph-title" }, { children: widget.name })), !props.exportMode && (_jsxs(_Fragment, { children: [_jsx(IconButton, __assign({ onClick: function () {
                                                        handleSelectedSectionUuid(sectionUuid);
                                                        setDeleteConfirmOpen(true);
                                                    }, style: { margin: "-12px 0 0 4px", minWidth: "36px" }, className: "delete-widget-button", "data-html2canvas-ignore": "true", disabled: isLoadingSettings }, { children: _jsx(MdDeleteOutline, { color: COLORS.RED, size: 22 }) })), _jsx(IconButton, __assign({ id: "edit-widget-button-".concat(widget.id), onClick: function () {
                                                        handleSelectedSectionUuid(sectionUuid);
                                                        setEditOpen(true);
                                                    }, style: { margin: "-12px 0 0 4px", minWidth: "36px" }, className: "edit-widget-button", "data-html2canvas-ignore": "true", disabled: isLoadingSettings }, { children: _jsx(BiEditAlt, { color: COLORS.DARKGREY, size: 22 }) })), props.widget.type === "ROSE_CHART" && (_jsx(IconButton, __assign({ id: "edit-widget-button-".concat(widget.id), onClick: props.exportFn, style: { margin: "-12px 0 0 4px", minWidth: "36px" }, className: "edit-widget-button", "data-html2canvas-ignore": "true" }, { children: _jsx(BiDownload, { color: COLORS.DARKGREY, size: 22 }) })))] })), _jsx(Logo, { src: PeCircleLogoWhite, className: "persium-logo", style: { display: "none", marginLeft: "auto" } })] })), children] })) })), _jsxs(Overlay, __assign({ className: grabbing && "grabbing", "data-html2canvas-ignore": "true", onMouseDown: function () {
                            setGrabbing(true);
                        }, onMouseUp: function () {
                            setGrabbing(false);
                        } }, { children: [_jsxs(Column, __assign({ className: "drag-message", alignItems: "center", justifyContent: "center" }, { children: [_jsx(TbDragDrop, { color: COLORS.BLACK, size: 22 }), _jsx(Prompt, __assign({ strong: true, style: { marginTop: "12px" } }, { children: "Drag to reposition" }))] })), _jsx(Tooltip, __assign({ id: "grow-shrink-tooltip", contents: isFullSize ? "Shrink" : "Expand" }, { children: _jsx(ActionButton, __assign({ size: 36, style: { bottom: "-10px", right: "-10px" }, onMouseDown: function (e) {
                                        e.stopPropagation();
                                    }, onClick: function (e) {
                                        e.stopPropagation();
                                        setSize(isFullSize ? HAWidgetSizes.HALF : HAWidgetSizes.FULL);
                                    } }, { children: _jsx(SizeIcon, { style: { transform: "rotate(90deg)" } }) })) }))] }))] })), _jsx(ConfirmationModal, { isOpen: deleteConfirmOpen, setIsOpen: setDeleteConfirmOpen, title: "Delete Graph", message: "Are you sure you want to remove this graph?", actionButtonText: "Delete", onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var response, remainingLayoutCells, newSettings;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                setDeleteConfirmOpen(false);
                                return [4 /*yield*/, deleteMultiViewSection({
                                        sectionUuid: selectedSectionUuid,
                                        uuid: selectedView.uuid,
                                    })];
                            case 1:
                                _a.sent();
                                return [4 /*yield*/, fetchMultiViewSections(selectedView.uuid)];
                            case 2:
                                response = _a.sent();
                                handleSections(response.data.data);
                                remainingLayoutCells = Dashboard.analytics.layout.filter(function (l) { return l.i !== widget.id; });
                                newSettings = {
                                    Dashboard: __assign({}, Dashboard),
                                };
                                // newSettings.Dashboard.analytics.widgets = remainingWidgets;
                                newSettings.Dashboard.analytics.layout = remainingLayoutCells;
                                dispatch(ActionCreators.updateSettings(newSettings));
                                return [2 /*return*/];
                        }
                    });
                }); } })] }));
});
WidgetWrapper.displayName = "WidgetWrapper";
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
