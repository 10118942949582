var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import { MdOutlineLegendToggle } from "react-icons/md";
import { FaCog } from "react-icons/fa";
import html2canvas from "html2canvas";
import { HAWidgetTypes, TimeRanges, } from "models";
import { Column, Row, IconButton, ExportDropdown, Button, DashStyles, SpeciesLineStyles, } from "components";
import { useDidMountEffect } from "hooks";
import { ActionCreators } from "store";
import styled from "styled-components";
import { useMultiViewStore } from "store/multi-view/store";
import { formatUTC, generateTimeFrame } from "utils";
import { fetchMultiViewOneSection, fetchMultiViewSections, updateMultiViewSection, } from "store/multi-view/api";
import { POD_COMPARISON_COLORS } from "theme";
import { Graph } from "./Graph";
import { Controls } from "./Controls";
import { Legend } from "./Legend";
import { WidgetWrapper } from "../../WidgetWrapper";
import { BuildGraphModal } from "../BuildGraphModal";
import { useViewId } from "hooks/useViewId";
var MyFakeTooltip = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\topacity: 0;\n\ttransition: opacity 200ms;\n\tposition: absolute;\n\tleft: 0;\n\ttop: 40px;\n\tpointer-events: none;\n\n\t&.visible {\n\t\tpointer-events: auto;\n\t\ttransition: opacity 200ms;\n\t\topacity: 1;\n\t}\n"], ["\n\topacity: 0;\n\ttransition: opacity 200ms;\n\tposition: absolute;\n\tleft: 0;\n\ttop: 40px;\n\tpointer-events: none;\n\n\t&.visible {\n\t\tpointer-events: auto;\n\t\ttransition: opacity 200ms;\n\t\topacity: 1;\n\t}\n"])));
var ControlColumn = styled(Column)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tmin-width: 320px;\n\twidth: 25%;\n\theight: 100%;\n\tmargin-right: 20px;\n\ttransition: all 200ms;\n\tpadding: 2px;\n\topacity: 1;\n\toverflow: hidden;\n\tmax-height: 480px;\n\n\t.HALF & {\n\t\tflex: 0;\n\t\tmin-width: 0;\n\t\twidth: 0;\n\t\theight: 0%;\n\t\tmargin-right: 0px;\n\t\tpadding: 0px;\n\t\topacity: 0;\n\t\tpointer-events: none;\n\t\ttransition: all 200ms;\n\t}\n"], ["\n\tmin-width: 320px;\n\twidth: 25%;\n\theight: 100%;\n\tmargin-right: 20px;\n\ttransition: all 200ms;\n\tpadding: 2px;\n\topacity: 1;\n\toverflow: hidden;\n\tmax-height: 480px;\n\n\t.HALF & {\n\t\tflex: 0;\n\t\tmin-width: 0;\n\t\twidth: 0;\n\t\theight: 0%;\n\t\tmargin-right: 0px;\n\t\tpadding: 0px;\n\t\topacity: 0;\n\t\tpointer-events: none;\n\t\ttransition: all 200ms;\n\t}\n"])));
var GraphColumn = styled(Column)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tposition: relative;\n\twidth: 75%;\n\tmax-width: calc(100% - 340px);\n\ttransition: all 200ms;\n\n\t.HALF & {\n\t\twidth: 100%;\n\t\tmax-width: 100%;\n\t\tmargin-top: -6px;\n\t\ttransition: all 200ms;\n\t}\n"], ["\n\tposition: relative;\n\twidth: 75%;\n\tmax-width: calc(100% - 340px);\n\ttransition: all 200ms;\n\n\t.HALF & {\n\t\twidth: 100%;\n\t\tmax-width: 100%;\n\t\tmargin-top: -6px;\n\t\ttransition: all 200ms;\n\t}\n"])));
var HeaderRow = styled(Row)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tposition: absolute;\n\ttop: 12px;\n\tleft: 0;\n\tright: 0;\n\tz-index: 100;\n\tpadding: 0 40px;\n\ttransition: all 200ms;\n\n\t.HALF & {\n\t\ttop: 0px;\n\t\tpadding: 0 5px 0 0;\n\t\ttransition: all 200ms;\n\t}\n"], ["\n\tposition: absolute;\n\ttop: 12px;\n\tleft: 0;\n\tright: 0;\n\tz-index: 100;\n\tpadding: 0 40px;\n\ttransition: all 200ms;\n\n\t.HALF & {\n\t\ttop: 0px;\n\t\tpadding: 0 5px 0 0;\n\t\ttransition: all 200ms;\n\t}\n"])));
var HeaderRowButtons = styled(Row)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\topacity: 0;\n\ttransition: all 200ms;\n\tposition: relative;\n\tpointer-events: none;\n\n\t.HALF & {\n\t\topacity: 1;\n\t\tpointer-events: auto;\n\t\ttransition: all 200ms;\n\t}\n"], ["\n\topacity: 0;\n\ttransition: all 200ms;\n\tposition: relative;\n\tpointer-events: none;\n\n\t.HALF & {\n\t\topacity: 1;\n\t\tpointer-events: auto;\n\t\ttransition: all 200ms;\n\t}\n"])));
var useGetTimeframe = function (_a) {
    var days = _a.days, end_time = _a.end_time, start_time = _a.start_time, timeFormat = _a.timeFormat;
    if (timeFormat === "fixed" && days) {
        var _b = generateTimeFrame(days), from_1 = _b.from, to_1 = _b.to;
        return { startFrame: from_1, endFrame: to_1 };
    }
    if (timeFormat === "calendar" && end_time && start_time) {
        return {
            startFrame: start_time,
            endFrame: end_time,
        };
    }
    var _c = generateTimeFrame(2), from = _c.from, to = _c.to;
    return { startFrame: from, endFrame: to };
};
/**
 * Graph block widget as shown on the Historics and Analytics
 */
export var GraphBlock = function (props) {
    var _a, _b, _c, _d, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
    var editMode = props.editMode, setWidgetSizeTracker = props.setWidgetSizeTracker, section = __rest(props, ["editMode", "setWidgetSizeTracker"]);
    var wrapperRef = useRef();
    var handleSections = useMultiViewStore().handleSections;
    var id = section.config.widget_id;
    var userInfo = useSelector(function (state) { return state.user.info.data; });
    var _6 = useGetTimeframe({
        days: section.config.days,
        end_time: section.config.end_time,
        start_time: section.config.start_time,
        timeFormat: section.config.time_format,
    }), endFrame = _6.endFrame, startFrame = _6.startFrame;
    var dispatch = useDispatch();
    var _7 = __read(useState(section.config.minutes || 60), 2), periodResolution = _7[0], setPeriodResolution = _7[1];
    var _8 = __read(useState(null), 2), sectionData = _8[0], setSectionData = _8[1];
    var _9 = __read(useState(true), 2), isSectionLoading = _9[0], setIsSectionLoading = _9[1];
    var selectedView = useViewId().selectedView;
    var refetchSection = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsSectionLoading(true);
                    return [4 /*yield*/, fetchMultiViewOneSection(selectedView.uuid, section.uuid)];
                case 1:
                    response = _a.sent();
                    setSectionData(response.data);
                    setIsSectionLoading(false);
                    return [2 /*return*/, response.data];
            }
        });
    }); };
    useEffect(function () {
        var interval = setInterval(function () { return __awaiter(void 0, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if ((sectionData === null || sectionData === void 0 ? void 0 : sectionData.status) === "completed")
                            return [2 /*return*/, clearInterval(interval)];
                        return [4 /*yield*/, refetchSection()];
                    case 1:
                        res = _a.sent();
                        if (res.status === "completed")
                            clearInterval(interval);
                        return [2 /*return*/];
                }
            });
        }); }, 10000);
        return function () { return clearInterval(interval); };
    }, [sectionData === null || sectionData === void 0 ? void 0 : sectionData.status]);
    var handleUpdatePeriodResolution = function (resolution) { return __awaiter(void 0, void 0, void 0, function () {
        var newSections;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!selectedView.uuid)
                        return [2 /*return*/];
                    setPeriodResolution(resolution);
                    return [4 /*yield*/, fetchMultiViewSections(selectedView.uuid)];
                case 1:
                    newSections = (_a.sent()).data.data;
                    handleSections(newSections);
                    return [4 /*yield*/, updateMultiViewSection({
                            sectionUuid: section.uuid,
                            uuid: selectedView.uuid,
                            body: {
                                name: section.name,
                                type: "graph",
                                config: __assign(__assign({}, section.config), { minutes: resolution }),
                            },
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var podsById = useSelector(function (state) { return state.pods.byId; });
    var errors = useSelector(function (state) { return state.pods.widgetErrors; });
    var _10 = __read(useState(), 2), chartRef = _10[0], setChartRef = _10[1];
    var _11 = __read(useState(false), 2), editOpen = _11[0], setEditOpen = _11[1];
    var _12 = __read(useState(false), 2), showMiniControls = _12[0], setShowMiniControls = _12[1];
    var _13 = __read(useState(false), 2), showMiniLegend = _13[0], setShowMiniLegend = _13[1];
    var _14 = __read(useState(section.config.size), 2), size = _14[0], setSize = _14[1];
    var _15 = __read(useState((_b = (_a = section.config.options) === null || _a === void 0 ? void 0 : _a.showAqiColors) !== null && _b !== void 0 ? _b : false), 2), showAqiColors = _15[0], setShowAqiColors = _15[1];
    var _16 = __read(useState((_d = (_c = section.config.options) === null || _c === void 0 ? void 0 : _c.showAsAverage) !== null && _d !== void 0 ? _d : false), 2), showAsAverage = _16[0], setShowAsAverage = _16[1];
    var _17 = __read(useState((_g = (_f = section.config.options) === null || _f === void 0 ? void 0 : _f.showMovingAverage) !== null && _g !== void 0 ? _g : false), 2), showMovingAverage = _17[0], setShowMovingAverage = _17[1];
    var _18 = __read(useState((_j = (_h = section.config.options) === null || _h === void 0 ? void 0 : _h.movingAverageInterval) !== null && _j !== void 0 ? _j : 5), 2), movingAverageInterval = _18[0], setMovingAverageInterval = _18[1];
    var _19 = __read(useState((_l = (_k = section.config.options) === null || _k === void 0 ? void 0 : _k.showMinMaxHighlights) !== null && _l !== void 0 ? _l : false), 2), showMinMaxHighlights = _19[0], setShowMinMaxHighlights = _19[1];
    var _20 = __read(useState((_o = (_m = section.config.options) === null || _m === void 0 ? void 0 : _m.showMinMaxAnnotations) !== null && _o !== void 0 ? _o : false), 2), showMinMaxAnnotations = _20[0], setShowMinMaxAnnotations = _20[1];
    var _21 = __read(useState((_q = (_p = section.config.options) === null || _p === void 0 ? void 0 : _p.showTrendLines) !== null && _q !== void 0 ? _q : false), 2), showTrendLines = _21[0], setShowTrendLines = _21[1];
    var refetchAllSections = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchMultiViewSections(selectedView === null || selectedView === void 0 ? void 0 : selectedView.uuid)];
                case 1:
                    response = _a.sent();
                    handleSections(response.data.data);
                    return [2 /*return*/];
            }
        });
    }); };
    /**
     * Any time the user may update the size of the widget, we call back to the H&A page parent
     * component and update state there.  That page component keeps track of all widgets' sizes
     * for layout purposes
     */
    useDidMountEffect(function () {
        setWidgetSizeTracker(function (sizes) {
            var _a;
            return (__assign(__assign({}, sizes), (_a = {}, _a[id] = size, _a)));
        });
    }, [size]);
    /**
     * Any time the user makes an update to one of the graph options, we save it in their settings
     * via the settings api
     */
    useDidMountEffect(function () {
        if (!selectedView.uuid)
            return;
        var fn = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, updateMultiViewSection({
                            sectionUuid: section.uuid,
                            uuid: selectedView.uuid,
                            body: {
                                type: "graph",
                                name: section.name,
                                config: __assign(__assign({}, section.config), { options: __assign(__assign({}, section.config.options), { movingAverageInterval: Math.floor(movingAverageInterval), showAqiColors: showAqiColors, showAsAverage: showAsAverage, showMinMaxAnnotations: showMinMaxAnnotations, showMinMaxHighlights: showMinMaxHighlights, showMovingAverage: showMovingAverage, showTrendLines: showTrendLines }), minutes: periodResolution }),
                            },
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, refetchSection()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        fn();
    }, [
        movingAverageInterval,
        periodResolution,
        showAqiColors,
        showAsAverage,
        showMinMaxAnnotations,
        showMinMaxHighlights,
        showMovingAverage,
        showTrendLines,
    ]);
    /**
     * Callback which clones the graph contents, adjusts them for good rendering
     * and branding, writes to canvas, and then downloads png
     */
    var pods = (_s = (_r = Object.values(podsById)) === null || _r === void 0 ? void 0 : _r.filter(function (pod) { return section.config.station_uuids.includes(pod.uuid); })) === null || _s === void 0 ? void 0 : _s.map(function (item) { return ({ name: item.name, uuid: item.uuid }); });
    var pollutantsSpecies = (_t = section.config.parameters.pollutants) === null || _t === void 0 ? void 0 : _t.map(function (item) { return ({
        aqi_colors: false,
        show_as_average: false,
        species_code: item,
        species_name: item,
        species_type: "pollutants",
    }); });
    var atmosphericsSpecies = (_u = section.config.parameters.atmospherics) === null || _u === void 0 ? void 0 : _u.map(function (item) { return ({
        aqi_colors: false,
        show_as_average: false,
        species_code: item,
        species_name: item,
        species_type: "atmospherics",
    }); });
    var engineeringsSpecies = (_v = section.config.parameters.engineerings) === null || _v === void 0 ? void 0 : _v.map(function (item) { return ({
        aqi_colors: false,
        show_as_average: false,
        species_code: item,
        species_name: item,
        species_type: "engineerings",
    }); });
    var species = [
        pollutantsSpecies,
        atmosphericsSpecies,
        engineeringsSpecies,
    ].flat();
    var handleDownloadAsPng = function () {
        html2canvas(wrapperRef.current, {
            /**
             * Before cloning and writing to a canvas, make some adjustments to the DOM so that a standalone
             * PNG makes more sense
             */
            onclone: function (document, element) {
                var logo = element.querySelector(".persium-logo");
                var legend = element.querySelector(".graph-widget-legend");
                logo.style.display = "inline-block";
                legend.style.marginTop = "0px";
                element.classList.add("no-box-shadows");
            },
        }).then(function (canvas) {
            var link = document.createElement("a");
            link.download =
                (pods === null || pods === void 0 ? void 0 : pods.map(function (p) { return p.name; }).join(", ")) +
                    " " +
                    [startFrame, endFrame].join(" - ").replaceAll("/", "-") +
                    " " +
                    "Persium";
            link.href = canvas.toDataURL();
            link.click();
        });
    };
    var handleRequestExport = function () {
        dispatch(ActionCreators.exportData({
            email: userInfo.email,
            end: endFrame,
            minutes: periodResolution,
            parameters: species === null || species === void 0 ? void 0 : species.map(function (item) { return item.species_code; }),
            start: startFrame,
            station_uuids: pods === null || pods === void 0 ? void 0 : pods.map(function (item) { return item.uuid; }),
        }));
    };
    var lines = [];
    if (!!((_x = (_w = sectionData === null || sectionData === void 0 ? void 0 : sectionData.result) === null || _w === void 0 ? void 0 : _w.data) === null || _x === void 0 ? void 0 : _x.length)) {
        (_z = (_y = sectionData === null || sectionData === void 0 ? void 0 : sectionData.result) === null || _y === void 0 ? void 0 : _y.data) === null || _z === void 0 ? void 0 : _z.forEach(function (i) {
            i.data.forEach(function (j, index) {
                var _a;
                var foundStation = podsById === null || podsById === void 0 ? void 0 : podsById[j.station_uuid];
                var lineItem = {
                    color: POD_COMPARISON_COLORS[index].primary,
                    dash: SpeciesLineStyles[i.parameter.toLowerCase()] || DashStyles.Solid,
                    pod: {
                        name: (foundStation === null || foundStation === void 0 ? void 0 : foundStation.name) || (foundStation === null || foundStation === void 0 ? void 0 : foundStation.address) || "Unknown Pod",
                        uuid: foundStation === null || foundStation === void 0 ? void 0 : foundStation.uuid,
                    },
                    data: {
                        data: {
                            items: (_a = j.data) === null || _a === void 0 ? void 0 : _a.map(function (item) { return ({
                                color: POD_COMPARISON_COLORS[index].primary,
                                date: formatUTC(item.time * 1000),
                                is_predict: false,
                                level: 0,
                                percent: 0,
                                unit: i.unit || "N/A",
                                value: item.value,
                            }); }),
                            page: 0,
                            total: 0,
                        },
                    },
                    species: {
                        aqi_colors: false,
                        show_as_average: false,
                        species_code: i.parameter,
                        species_name: i.parameter,
                        // FIXME: Read from response
                        species_type: "pollutants",
                    },
                };
                lines.push(lineItem);
            });
        });
    }
    var avgLines = [];
    if (!!((_1 = (_0 = sectionData === null || sectionData === void 0 ? void 0 : sectionData.result) === null || _0 === void 0 ? void 0 : _0.average_data) === null || _1 === void 0 ? void 0 : _1.length)) {
        (_3 = (_2 = sectionData === null || sectionData === void 0 ? void 0 : sectionData.result) === null || _2 === void 0 ? void 0 : _2.average_data) === null || _3 === void 0 ? void 0 : _3.forEach(function (i, index) {
            var _a;
            var lineItem = {
                color: POD_COMPARISON_COLORS[index].primary,
                dash: SpeciesLineStyles[i.parameter.toLowerCase()] || DashStyles.Solid,
                pod: {
                    name: "Average",
                    uuid: "AVERAGE",
                },
                data: {
                    data: {
                        items: (_a = i.data) === null || _a === void 0 ? void 0 : _a.map(function (item) { return ({
                            color: POD_COMPARISON_COLORS[index].primary,
                            date: formatUTC(item.time * 1000),
                            is_predict: false,
                            level: 0,
                            percent: 0,
                            unit: i.parameter || "N/A",
                            value: item.value,
                        }); }),
                        page: 0,
                        total: 0,
                    },
                },
                species: {
                    aqi_colors: false,
                    show_as_average: false,
                    species_code: i.parameter,
                    species_name: i.parameter,
                    // FIXME: Read from response
                    species_type: "pollutants",
                },
            };
            avgLines.push(lineItem);
        });
    }
    var graphState = {
        loading: isSectionLoading,
        lines: showAsAverage ? avgLines : lines,
    };
    var specieNames = __spreadArray([], __read(new Set((_4 = graphState.lines) === null || _4 === void 0 ? void 0 : _4.map(function (item) { return item.species.species_code; }))), false);
    var podNames = __spreadArray([], __read(new Set((_5 = graphState.lines) === null || _5 === void 0 ? void 0 : _5.map(function (item) { var _a; return (_a = item.pod) === null || _a === void 0 ? void 0 : _a.name; }))), false);
    var graphName = !!podNames.length &&
        !!specieNames.length &&
        "".concat(podNames.join(", "), ", ").concat(specieNames.join(", "));
    var gn = graphName || "";
    var widgetName = __spreadArray(__spreadArray([], __read(podNames), false), __read(specieNames), false).length
        ? graphName
        : graphState.loading
            ? "Loading...".concat(gn ? "," : "", " ").concat(gn)
            : sectionData.status === "in-progress"
                ? "In Progress...".concat(gn ? "," : "", " ").concat(gn)
                : graphState.lines.length === 0
                    ? "Data Not Available".concat(gn ? "," : "", " ").concat(gn)
                    : "";
    return (_jsxs("div", __assign({ ref: wrapperRef, style: { height: "100%" } }, { children: [_jsx(WidgetWrapper, __assign({ id: "graph-widget-".concat(id), elevation: 10, hidden: false, size: size, setSize: setSize, editMode: editMode, setEditOpen: setEditOpen, sectionUuid: section.uuid, widget: {
                    id: section.widgetUuid,
                    name: widgetName,
                    size: section.config.size,
                    type: HAWidgetTypes.GRAPH,
                } }, { children: !errors[id] ? (_jsxs(Row, __assign({ alignItems: "flex-start", justifyContent: "flex-start" }, { children: [_jsxs(ControlColumn, __assign({ margin: "0 20px 0 0", style: { overflow: "auto" } }, { children: [_jsxs(Column, __assign({ style: { width: "100%", maxHeight: "478px" } }, { children: [_jsx(Controls, { graphState: graphState, selectedAvg: periodResolution, setSelectedAvg: handleUpdatePeriodResolution, showAqiColors: showAqiColors, showAsAverage: showAsAverage, showMovingAverage: showMovingAverage, setShowAqiColors: setShowAqiColors, setShowAsAverage: setShowAsAverage, setShowMovingAverage: setShowMovingAverage, movingAverageInterval: movingAverageInterval, setMovingAverageInterval: setMovingAverageInterval, showMinMaxHighlights: showMinMaxHighlights, setShowMinMaxHighlights: setShowMinMaxHighlights, showTrendLines: showTrendLines, setShowTrendLines: setShowTrendLines, showMinMaxAnnotations: showMinMaxAnnotations, setShowMinMaxAnnotations: setShowMinMaxAnnotations, config: {
                                                dateFormat: section.config.time_format,
                                                options: __assign(__assign({}, section.config.options), { periodResolution: periodResolution }),
                                                pods: pods,
                                                species: species,
                                                timeframe: section.config.selectedTimeframe,
                                                customFrame: section.config.days && String(section.config.days),
                                                dateRange: {
                                                    from: section.config.start_time,
                                                    to: section.config.end_time,
                                                },
                                            }, id: section.widgetUuid, name: section.name, size: section.config.size, type: HAWidgetTypes.GRAPH }), _jsx(Legend, { graphState: graphState, widgetId: id })] })), _jsx(Column, __assign({ style: { width: "100%", maxHeight: "478px" } }, { children: _jsxs("div", __assign({ style: {
                                            padding: "10px",
                                            display: "flex",
                                            flexDirection: "column",
                                            border: "2px solid #EAEBED",
                                            width: "100%",
                                            borderRadius: "20px",
                                            marginTop: "10px",
                                            gap: "8px",
                                        } }, { children: [_jsx("span", __assign({ style: { fontWeight: "bold", fontSize: "16px" } }, { children: "Time Period" })), _jsxs("span", __assign({ style: { fontWeight: "400", fontSize: "14px" } }, { children: ["From: ", startFrame] })), _jsxs("span", __assign({ style: { fontWeight: "400", fontSize: "14px" } }, { children: ["To: ", endFrame] }))] })) }))] })), _jsxs(GraphColumn, { children: [_jsxs(HeaderRow, __assign({ "data-html2canvas-ignore": "true" }, { children: [_jsxs(HeaderRowButtons, { children: [_jsx(OutsideClickHandler, __assign({ onOutsideClick: function () { return setShowMiniControls(false); } }, { children: _jsx(MyFakeTooltip, __assign({ className: showMiniControls && "visible" }, { children: _jsx(Controls, { graphState: graphState, selectedAvg: periodResolution, setSelectedAvg: handleUpdatePeriodResolution, showAqiColors: showAqiColors, showAsAverage: showAsAverage, showMovingAverage: showMovingAverage, setShowAqiColors: setShowAqiColors, setShowAsAverage: setShowAsAverage, setShowMovingAverage: setShowMovingAverage, movingAverageInterval: movingAverageInterval, setMovingAverageInterval: setMovingAverageInterval, showMinMaxHighlights: showMinMaxHighlights, setShowMinMaxHighlights: setShowMinMaxHighlights, showTrendLines: showTrendLines, setShowTrendLines: setShowTrendLines, showMinMaxAnnotations: showMinMaxAnnotations, setShowMinMaxAnnotations: setShowMinMaxAnnotations, mini: true, config: {
                                                                dateFormat: section.config.time_format,
                                                                options: __assign(__assign({}, section.config.options), { periodResolution: periodResolution }),
                                                                pods: pods,
                                                                species: species,
                                                                timeframe: section.config.selectedTimeframe,
                                                                customFrame: section.config.days && String(section.config.days),
                                                                dateRange: {
                                                                    from: section.config.start_time,
                                                                    to: section.config.end_time,
                                                                },
                                                            }, id: section.widgetUuid, name: section.name, size: section.config.size, type: HAWidgetTypes.GRAPH }) })) })), _jsx(IconButton, __assign({ onClick: function () { return setShowMiniControls(true); } }, { children: _jsx(FaCog, { size: 20 }) })), _jsx(OutsideClickHandler, __assign({ onOutsideClick: function (_e) {
                                                        setShowMiniLegend(false);
                                                    } }, { children: _jsx(MyFakeTooltip, __assign({ className: showMiniLegend && "visible" }, { children: _jsx(Legend, { graphState: graphState, widgetId: id, mini: true }) })) })), _jsx(IconButton, __assign({ onClick: function () { return setShowMiniLegend(true); } }, { children: _jsx(MdOutlineLegendToggle, { size: 24 }) }))] }), _jsx(ExportDropdown, { disabled: graphState === null || graphState === void 0 ? void 0 : graphState.loading, onPng: handleDownloadAsPng, onCsv: handleRequestExport, onShare: function () { } })] })), _jsx(Graph, { graphState: graphState, chartRef: chartRef, setChartRef: setChartRef, showAqiColors: showAqiColors, showAsAverage: showAsAverage, showMovingAverage: showMovingAverage, movingAverageInterval: movingAverageInterval, showMinMaxAnnotations: showMinMaxAnnotations, showMinMaxHighlights: showMinMaxHighlights, showTrendLines: showTrendLines, sectionData: sectionData, config: {
                                        dateFormat: section.config.time_format,
                                        options: __assign(__assign({}, section.config.options), { periodResolution: periodResolution }),
                                        pods: pods,
                                        species: species,
                                        timeframe: section.config.selectedTimeframe,
                                        customFrame: section.config.days && String(section.config.days),
                                        dateRange: {
                                            from: section.config.start_time,
                                            to: section.config.end_time,
                                        },
                                    }, id: section.widgetUuid, name: section.name, size: section.config.size, type: HAWidgetTypes.GRAPH })] })] }))) : (_jsxs("div", __assign({ style: {
                        height: "90%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "16px",
                        color: "red",
                    } }, { children: [_jsx("p", { children: "Something went wrong" }), _jsx(Button, __assign({ onClick: refetchSection }, { children: "Refresh" }))] }))) })), _jsx(BuildGraphModal, { isOpen: editOpen, setIsOpen: setEditOpen, refetchSection: function () {
                    refetchSection();
                }, edit: {
                    customFrame: TimeRanges.CUSTOM === section.config.selectedTimeframe
                        ? section.config.days
                        : 0,
                    dateFormat: section.config.time_format === "calendar" ? "range" : "frame",
                    selectedSpecies: species,
                    selectedTimeframe: section.config.selectedTimeframe,
                    seletectedPods: pods === null || pods === void 0 ? void 0 : pods.map(function (pod) { return podsById[pod.uuid]; }).filter(Boolean),
                    widgetId: id,
                    // selectedDate: config.config.dateRange,
                } })] })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
