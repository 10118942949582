var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useSearchParams } from "react-router-dom";
import { useMultiViewStore } from "store/multi-view";
export var useViewId = function () {
    var _a;
    var views = useMultiViewStore().views;
    var _b = __read(useSearchParams(), 2), searchParams = _b[0], setSearchParams = _b[1];
    var selectedView = (_a = views.find(function (item) { return item.uuid === searchParams.get("uuid"); })) !== null && _a !== void 0 ? _a : {
        uuid: undefined,
    };
    return {
        selectedView: selectedView,
        setSelectedView: function (uuid) { return setSearchParams("uuid=".concat(uuid)); },
    };
};
